<template>
    <div class="modal fade" id="modalFolderAdd" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="folderAddLabel" aria-hidden="true">    
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-pencil-alt text-primary"></i> Add a folder</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">
                    <!-- Error msg-->
                    <div v-if="folderAddWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{folderAddWarning.message}}		
                        <button class="btn btn-sm btn-default pull-right" v-on:click="folderAddWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->
                    <div class="row">
                        <div class="col">                                 
                            <input type="text" class="form-control" v-model="folderName"  placeholder="Folder name">
                        </div>

                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
                    <button :disabled="folderName.trim() == '' || loading" class="btn btn-primary" type="button" v-on:click="addFolder()">
                        Ok
                    </button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {        
    created () {
        
    },
    data: function() {
        return {
            folderName: '',
            loading: false,
            folderAddWarning: {'visible': false, 'message': ''}
        }
    },
    methods: {
        cancel: function() {
            this.$parent.modalFolderAdd.hide();          
        },
        addFolder: function() {
            this.$root.processing = true;
            var _this = this;            
            var postData = {
				parent: this.$root.currentFolder.id,
                action: 'add',
                name: this.folderName.trim()			
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                    
                _this.$root.processing = false;      
                _this.folderName = '';                							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {          
                    _this.$root.driveData.unshift(response.data);                    
                    _this.cancel();
                    _this.$root.setAlert(true, 'Folder added successfully', 'alert-success');					
				}
			});
        }      
    }
}
</script>