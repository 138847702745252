<template>    
	<div class="container-fluid" style="padding-bottom: 80px; padding-right: 420px">
		<div class="row pb-2 h-100">
			<div class="col-10 offset-md-2">
				<div class="row">
					<div class="col-2 border-bottom">		
						<span class="display-5"><i class="text-primary fa-xs fa-fw fad" :class="{'fa-folder-open': !$root.loading && !$root.processing, 'fa-circle-notch fa-spin': $root.loading || $root.processing}"></i> Documents</span>						
					</div>			
					<div class="col-4 border-bottom pt-1">
						<search></search>
					</div>
					<div class="col border-bottom mt-3 text-end">
						<div class="mb-2">
							<button type="button" data-bs-toggle="modal" data-bs-target="#modalFolderAdd" class="btn btn-sm btn-primary me-1"><i class="fas fa-folder-plus"></i> Add a folder</button>
							<button type="button" data-bs-toggle="modal" data-bs-target="#modalLinkAdd" class="btn btn-sm btn-primary me-1"><i class="fas fa-map-marker-plus"></i> Add a link</button>
							<button type="button" v-on:click="addDocument()" class="btn btn-sm btn-primary"><i class="fas fa-file-plus"></i> Upload documents</button>
							<input type="file" multiple ref="fileUpload" id="fileUpload" v-on:change="uploadFiles()">
						</div>
					</div>
				</div>
			
				<div v-if="!$root.loading">					
					<!-- Toolbar -->
					<div class="row">
						<div class="col">
							<breadcrumb></breadcrumb>					
						</div>										
					</div>
					<!-- Drive items-->
					<div>															
						<item-list :data="$root.driveData"></item-list>										
					</div>					
				</div>		
			</div>			
		</div>
		<detail v-if="!$root.loading"></detail>
	</div>  
</template>

<script>
import itemList from './drive/item.list.vue';
import breadcrumb from './common/breadcrumb.vue';
import detail from './drive/detail.vue';
import search from './search/main.vue';

export default {
	components: {			
		itemList,
		breadcrumb,
		detail,
		search	
	},

	data: function() {
    	return {				
			files: []
		}
	},
	methods: {	
		hideContextMenu(id) {
      		const element = document.getElementById(id);
      		if (element) {
        		element.classList.remove("vue-simple-context-menu--active");
        		//this.$emit("menu-closed");
      		}
    	},			
		addDocument: function() {
			$("#fileUpload").trigger("click");			
		},			
		uploadFiles: function() {
			if (this.$refs.fileUpload.files.length > 0) {
				this.$root.processing = true;
				var _this = this;
				var data = {
					parent: this.$root.currentFolder.id,
					action: 'add'				
				};				
				var formData = new FormData();
				formData.append("data", JSON.stringify(data));
				for (var f = 0; f < this.$refs.fileUpload.files.length; f++) {
					formData.append("files[]", this.$refs.fileUpload.files[f]);
				}
				this.axios({
					method: 'post'
					,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
					,headers: {'Content-Type': 'multipart/form-data'}				
					,data: formData
				})
				.then(function(response) {							
					_this.$root.processing = false;
					if (response.data.error) {	
						_this.$root.setAlert(true, response.data.error, 'alert-danger');									
						return;
					} else {     						        
						for (let i = 0; i < response.data.length; i++) {
							_this.$root.driveData.unshift(response.data[i])
						}						
						_this.$root.setAlert(true, 'Files added successfully', 'alert-success');					
					}
				});
			}	
		},
    	setAlert: function(display, message, css, icon) {
			this.$root.setAlert(display, message, css, icon);			
		},
		clearAlert: function() {
			this.$root.clearAlert();
		},		
				
	},
	computed: {		
	},
	mounted() {
		this.$root.getData();
	},
	watch: {	
		'$route': function(newVal, oldVal) {			
			this.$root.getData();
		}				
	}
}
</script>
<style>
#fileUpload, #fileUpdate {
	display: none;
}
.form-label-group .input-group-text input {
	height: inherit;
	padding-left: 5px !important;
	padding-top: 13px !important;
}

.custom-control-label {
	padding-left: .25rem;
	padding-top: 0.1rem;
}
.custom-control-label::before, 
.custom-control-label::after {  
  width: 1.25rem;
	height: 1.25rem;	
}

@keyframes editing {
 0%  {margin-left: 0px;}
 100% {margin-left: 20px;}
}

@keyframes subdued {
 0%  {margin-left: 20px;}
 100% {margin-left: 0px;}
}

.display-5 {
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}
</style>