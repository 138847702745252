var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('tinymce',{attrs:{"api-key":"qljuc25ts0wq31b58jatuhk6milgrsuypqacaat276rpfenn","id":'tinymce-'+_vm.id,"value":_vm.value,"init":{
            toolbar: 'bold italic link | undo redo | cut copy paste',
            menubar: false,
            statusbar: false,
            autoresize_on_init: true,
            plugins: [
    		    'link table spellchecker lists autolink hr paste autoresize'
		    ],
            init_instance_callback: (editor) => {
                editor.on('KeyUp', (e) => {
                    this.$emit('input', editor.getContent());
                });
                editor.on('Change', (e) => {                
                    this.$emit('input', editor.getContent());                                        
                });
                editor.setContent(this.value);                
            } 
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }