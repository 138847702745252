<template>
    <div>
      <tinymce         
        api-key="qljuc25ts0wq31b58jatuhk6milgrsuypqacaat276rpfenn"
        :id="'tinymce-'+id" 
        :value="value"
        :init="{
            toolbar: 'bold italic link | undo redo | cut copy paste',
            menubar: false,
            statusbar: false,
            autoresize_on_init: true,
            plugins: [
    		    'link table spellchecker lists autolink hr paste autoresize'
		    ],
            init_instance_callback: (editor) => {
                editor.on('KeyUp', (e) => {
                    this.$emit('input', editor.getContent());
                });
                editor.on('Change', (e) => {                
                    this.$emit('input', editor.getContent());                                        
                });
                editor.setContent(this.value);                
            } 
        }"
        ></tinymce>
    </div>
</template>

<script>
import tinymce from '@tinymce/tinymce-vue';
export default {	
    components: {
        tinymce: tinymce
    },
    data: function() {
        return {
            id: this.$root.getEditorId() //Each tinyMCE editor needs a uniquie ID
        }
    },  
    props: {        
        value: {
            type: String,
            default : ''
        },
        toolbar: {
            default: 'bold italic | undo redo | paste'
        },
        menubar: {
            default: 'edit help'
        },
        otherProps: {
            default: ''
        }
    }
}
</script>