<template>
     <div>
        <div class="input-group search">
            <div class="input-group-text bg-white" v-on:click="doSearch()">
                <span class="fa-stack" :class="{'text-secondary disabled': search.trim() == ''}"><i class="fas fa-search"></i></span>         
            </div>
            <input class="form-control border-start-0 border-end-0 shadow-none" v-on:keyup.enter="doSearch()" v-model="search" type="text" placeholder="Search documents" aria-label="Search">  
            <span v-show="search > ''" v-on:click="resetSearch()" class="input-group-text bg-white"><i class="fa fa-times"></i></span>    
            <div class="input-group-text border-start-0 bg-white dropdown">
                <span class="fa-stack" id="toggleOptions" data-bs-toggle="dropdown"><i class="fas fa-sliders-h"></i></span>         
                <div v-on:click="$event.stopPropagation();" id="searchOptions" class="dropdown-menu dropdown-menu-end" aria-labelledby="toggleOptions">
                    <div class="row p-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3 text-secondary">Search for</div>
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemType" id="itemTypeAll" v-model="itemType" value="all">
                                        <label class="form-check-label" for="itemTypeAll">Files and folders</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemType" id="itemTypeFile" v-model="itemType" value="file">
                                        <label class="form-check-label" for="itemTypeFile">Files only</label>
                                    </div>
                                     <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemType" id="itemTypeFolder" v-model="itemType" value="folder">
                                        <label class="form-check-label" for="itemTypeFolder">Folders only</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 pt-2 border-top">
                                <div class="col-3 text-secondary">Search type</div>
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="searchType" id="searchTypeName" v-model="searchType" value="name">
                                        <label class="form-check-label" for="searchTypeName">Item title</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="searchType" id="searchTypeFullText" v-model="searchType" value="fullText">
                                        <label class="form-check-label" for="searchTypeFullText">Item contents</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 pt-2 border-top">
                                <div class="col-3 text-secondary">Locations</div>
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemLocation" id="itemLocationDocuments" v-model="itemLocation" value="documents">
                                        <label class="form-check-label" for="itemLocationDocuments">Documents</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemLocation" id="itemLocationTrash" v-model="itemLocation" value="trash">
                                        <label class="form-check-label" for="itemLocationTrash">Trash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="itemLocation" id="itemLocationBoth" v-model="itemLocation" value="all">
                                        <label class="form-check-label" for="itemLocationBoth">Documents and trash</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                      
            </div>             
        </div>  
    </div> 
</template>
<script>
export default {
    data() {
        return {
            search: '',
            searchType: 'name', //fullText 
            itemType: 'all',
            itemLocation: 'documents',
            parent: '',
            nextPage: false               
        }
    },    
    methods: {
        resetSearch: function() {
            this.search = '';
            this.searchType = 'name';
            this.itemType = 'all';
            this.itemLocation = 'documents';
            this.parent = '';
            this.$root.searchPageToken = false;
        },
        doSearch: function() {            
            if (this.search.trim() == '') {
                return;
            }
            var _this = this;
            this.$root.loading = true;            
            if (this.$route.name != 'adminSearchResult') {
                _this.$router.push({name: 'adminSearchResult'});
            }                        
            var params = {
                search: this.search,
                searchType: this.searchType,
                itemType: this.itemType,
                itemLocation: this.itemLocation,
                parent: this.parent                
            }            
            this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/search.php', {params: params})
            .then(response => {                              
                _this.$root.loading = false;                
                if (response.data.error) {
                    _this.$root.setAlert(true, response.data.error, 'alert-danger');              
                    return;
                } else {                                        
                    _this.$root.searchData = response.data;
                }
            });
        }
    },
    created() {
        var _this = this;
        if (this.search.trim() == '' && this.$route.name == 'adminSearchResult') {
            this.$router.replace({name: 'adminDocuments'});
        }        
    }
}
</script>
<style scoped>
.search {
    min-width: 550px;
    max-width: 550px;
    margin-left: 58px;
}
.search span {
    cursor: pointer;
}

#searchOptions {        
    width: 548px;  
    margin-right: 0px;
    margin-top: -2px;
    box-shadow: 0px 3px 5px #999;
    border: none;
}

.fa-stack {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #FFF;
    box-sizing: border-box;    
}

.fa-stack:hover {
    background: #e9ecef;    
}

.fa-stack.disabled:hover {
    background: #FFF;
    cursor: default;
}

.form-control:hover, .form-control:active, .form-control:focus {
	outline: transparent !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
	border-color: none;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
}

</style>