//Baee components
import Vue from 'vue';
import app from './app.vue';

//External modules
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import VueSimpleContextMenu from "vue-simple-context-menu";

//CSS
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/js/fontawesome.js';
//import './assets/fontawesome/all.min.js'; //FA6
import './assets/css/screen.css';
import './assets/css/floating-labels.css';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';


//jQuery
window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false;

Vue.component('alert', alert);
Vue.component('foot', foot);
Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);

//Internal modules
import userHome from './components/user/main.vue';
import userSearchResult from './components/user/searchResult.vue';

import adminHome from './components/admin/main.vue';
import adminUsers from './components/admin/users.vue';
import adminStaff from './components/admin/staff.vue';
import adminDocuments from './components/admin/documents.vue';
import adminSearchResult from './components/admin/searchResult.vue';
import adminTrash from './components/admin/trash.vue';
import adminNavigation from './components/admin/common/navigation.vue';

import userNavigation from './components/common/navigation.vue';

import login from './components/common/login.vue';
import alert from './components/common/alert.vue';
import foot from './components/common/foot.vue';

//jQuery
window.$ = window.jQuery = require('jquery');

const alertDefault = {
  display: false,
  message: '',
  class: 'alert-primary',
  timeout: false
};

//Set to false to set the application to inactive, all routes will redirect to the /inactive route
const active = true;

//Routes and route components
const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_BASE_SRC,
	routes: [
        { 
			name: 'login', 
			path: '/login/:state?',
            components: {
                default: login                
            },
            props: {
                default: true                
            }		
		},
        {
            name: 'userHome',
            path: '/',
            components: {
                default: userHome,
                navigation: userNavigation                
            },
            props: {
                default: true,
                navigation: true
            }  
        },
        { 
            name: 'userSearchResult',
            path: '/search', 
            components: {
                default: userSearchResult,
                navigation: userNavigation
            },
            props: {
                default: true,
                navigation: true
            }     
        },
        { 
            name: 'adminHome',
            path: '/admin/', 
            components: {
                default: adminHome,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }   
        },
        { 
            name: 'adminDocuments',
            path: '/admin/documents/:id?', 
            components: {
                default: adminDocuments,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }   
        },        
        { 
            name: 'adminSearchResult',
            path: '/admin/search', 
            components: {
                default: adminSearchResult,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }     
        },
        { 
            name: 'adminUsers',
            path: '/admin/users/:id?', 
            components: {
                default: adminUsers,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }   
        },
        { 
            name: 'adminStaff',
            path: '/admin/staff/:id?', 
            components: {
                default: adminStaff,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }   
        },
        { 
            name: 'adminTrash',
            path: '/admin/trash/:id?', 
            components: {
                default: adminTrash,
                navigation: adminNavigation
            },
            props: {
                default: true,
                navigation: true
            }		
        },           
		{
			path: '*',
			redirect: '/'
		}
	]
});


router.beforeEach((to, from, next) => {		
	if (!active && to.name != 'inactive') {      				
    	next('/inactive');
        return;
    }
    next();
});



fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    Vue.prototype.$config = config;      
    new Vue({
		router,
		data: {
			user: {},
            alert:  JSON.parse(JSON.stringify(alertDefault)),
            env: process.env.VUE_APP_ENV,
            loading: false, 
            fetching: false,           
            driveData: false,  
            userData: [],
            staffData: [],
            searchData: [],        
            //breadcrumb: [],      
            trashData: [],                
            loggedIn: false,
            config: {},
            itemDefault: {},
            currentItem: {},
            currentItems: [],
            currentFolder: {},
            editorId: 0   		
		},
		methods: {			
			isLoggedIn: function () {				
				const c = this.$config.app_cookie;									
				if (this.$cookies.isKey(c)) {					
					this.user = this.$cookies.get(c);					
					this.loggedIn = true;					
				} else {	
                    if (this.$route.name != 'login') {
                        this.$router.push({name: 'login'});									
                    }
				}
			},
            logout: function() {
                const c = this.$config.app_cookie;						
                this.$cookies.remove(c);
                this.user = {};
                this.loggedIn = false;
                if (this.$route.name != 'login') {
                    this.$router.push({name: 'login', params: {state: 'logout'}});
                }
            },
			clearAlert: function() {			
				this.alert = JSON.parse(JSON.stringify(alertDefault));
			},
            getEditorId: function() {
                this.editorId++;
                return this.editorId;
            },
			setAlert: function(display, message, css) {
				this.alert.display = display;
				this.alert.class = css;
				this.alert.message = message;			
			},
            setCurrent: function(e) {                
                if ($(e.target).parents(".modal").length || $(e.target).hasClass("modal") || $(e.target).parents("#detail").length || $(e.target).hasClass("goto") || $(e.target).parent().hasClass("goto")) {            
                  return;
                }   
                var t = '';
                if ($(e.target).is("li.item-wrapper__item")) {
                    t = $(e.target);
                }
                if ($(e.target).is(".file, .folder, .link")) {
                    t = $(e.target).parent().parent();
                }
                if ($(e.target).is(".file img, .folder img")) {
                    t = $(e.target).parent().parent().parent();
                }               
                if ($(e.target).is(".link i, .folder i, .file i")) {
                    t = $(e.target).parent().parent().parent().parent();
                } 
                if ($(e.target).find(".bg-warning").length) {       //Search results            
                    t = $(e.target).parent().parent().parent();
                }   
                if ($(e.target).is(".bg-warning")) {       //Search results            
                    t = $(e.target).parent().parent().parent().parent();
                }       
                if (!e.ctrlKey && !e.shiftKey) {          
                    $("li.item-wrapper__item").removeClass("current");
                    if (t) {
                        t.addClass("current");  
                    } else {
                        this.$root.currentItems = [];
                        this.$root.currentItem = {};
                    }
                }                 
            },
            getTrash: function() {
                var _this = this;
                const trashLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/trash.get.php');   
                Promise.all([trashLookup])
                .then(response => {	              
                    for (var d = 0; d < response.length; d++) {
                        if (response[d].data.error) {
                            _this.$root.setAlert(true, response[d].data.error, 'alert-danger');
                            _this.loading = false;
                            return;
                        }
                    }            
                    _this.trashData = response[0].data;		            	
                    _this.loading = false;						
                }).catch(error => {
                    _this.$root.setAlert(true, error, 'alert-danger');                
                    _this.loading = false;
                    return;
                });
            },                    
            getData: function() {	
                this.itemDefault = {id: this.$config.rootId};
                var _this = this;
                this.loading = true;  
                this.fetching = true;        
                var id = this.$config.rootId;
                if (this.$route.params.id) {
                    id = this.$route.params.id;
                }                        
                const driveLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/drive.get.php', {params: {id: id}});                        
                //const breadCrumbLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/breadcrumb.get.php', {params: {id: id}});                        
                const folderLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.get.php', {params: {id: id, action: 'info'}});                        
      
                Promise.all([driveLookup, folderLookup])
                .then(response => {	                                            
                    for (var d = 0; d < response.length; d++) {
                        if (response[d].data.error) {
                            _this.$root.setAlert(true, response[d].data.error, 'alert-danger');
                            _this.loading = false;
                            return;
                        }
                    }                        
                    _this.driveData = JSON.parse(JSON.stringify(response[0].data));	                     
                    //_this.breadcrumb = response[1].data;	                   
                    _this.currentItem = response[1].data;	                           
                    _this.currentFolder = JSON.parse(JSON.stringify(_this.currentItem));               
                    _this.loading = false;					
                }).catch(error => {
                    _this.$root.setAlert(true, error, 'alert-danger');                
                    _this.loading = false;
                    return;
                });         			            
            },
            getUsers: function() {
                this.itemDefault = {id: this.$config.userId};
                var _this = this;
                this.loading = true;          
                var id = this.$config.userId;
                if (this.$route.params.id) {
                    id = this.$route.params.id;
                }                        
                const driveLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/drive.get.php', {params: {id: id, type: 'user'}});                                                              
                Promise.all([driveLookup])
                .then(response => {	                                            
                    for (var d = 0; d < response.length; d++) {
                        if (response[d].data.error) {
                            _this.$root.setAlert(true, response[d].data.error, 'alert-danger');
                            _this.loading = false;
                            return;
                        }
                    }  
                    let data = JSON.parse(JSON.stringify(response[0].data));
                    _this.userData = data.filter(u => { return u.properties.type == 'user' });
                    _this.staffData = data.filter(s => { return s.properties.type == 'staff' });                    
                    _this.loading = false;						
                }).catch(error => {
                    _this.$root.setAlert(true, error, 'alert-danger');                
                    _this.loading = false;
                    return;
                });
            }
        },
        mounted() {         
            this.isLoggedIn();     
            this.getData();
            //this.getTrash();
            this.$el.addEventListener('click', this.setCurrent);
        },
        beforeDestroy: function () {
            this.$el.removeEventListener('click', this.setCurrent);                
		},		
		render: (h) => h(app)
    }).$mount('#app');
  });
Vue.filter('highlight', function(item, query) {
	var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
	var repl = query.replace(invalid, "");
	var check = new RegExp(repl, "ig");
	return item.toString().replace(check, function(matchedText,a,b){
		return ('<span class="bg-warning">' + matchedText + '</span>');
	});
});
Vue.filter('currency', function(value, dec) {
	dec = dec || 0;
	return value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");	
});