<template>
    <div>     
        <span v-if="!data">
             <h5 class="pt-2"><i class="fad fa-hourglass-half"></i> Fetching folder data...</h5>
        </span> 
        <div v-else-if="data && data.length > 0">                 
            <ul>
                <li v-on:dblclick="dblClick($event, d)" 
                    v-on:click="handleClick($event, d, k)"
                    v-on:contextmenu.prevent.stop="handleRightClick($event, d, k)"                 
                    class="item-wrapper__item" 
                    :id="d.id"
                    :key="k"
                    :data-key="k" 
                    v-for="(d, k) in data"> 
                    <span v-if="$route.name == 'adminSearchResult'">   
                        <span v-if="d.mimeType == 'application/vnd.google-apps.folder'" class="folder"><img :src="d.iconLink" /> <span v-html="$options.filters.highlight(d.name, filter.toLowerCase())"></span> <access-icon :data="d.properties"></access-icon></span>
                        <span  v-else-if="d.mimeType == 'text/x-uri'" class="link"><i class="fas fa-external-link-square" /> <span v-html="$options.filters.highlight(d.name, filter.toLowerCase())"></span> <access-icon :data="d.properties"></access-icon></span>
                        <span v-else class="file"><img :src="d.iconLink" /> <span v-html="$options.filters.highlight(d.name, filter.toLowerCase())"></span> <access-icon :data="d.properties"></access-icon></span>              
                    </span>
                    <span v-else>
                        <span v-if="d.mimeType == 'application/vnd.google-apps.folder'" class="folder"><img :src="d.iconLink" /> {{d.name}} <access-icon :data="d.properties"></access-icon></span>
                        <span  v-else-if="d.mimeType == 'text/x-uri'" class="link"><i class="fas fa-external-link-square" /> {{d.name}} <access-icon :data="d.properties"></access-icon></span>
                        <span v-else class="file"><img :src="d.iconLink" /> {{d.name}} <access-icon :data="d.properties"></access-icon></span>   
                    </span>
                </li>
            </ul>                     
        </div>
        <h5 class="pt-2" v-else>
            <span v-if="$route.name == 'adminSearchResult'">Your search returned no results</span>
            <span v-else><i class="far fa-folder"></i> This folder is empty</span>
        </h5>
        <vue-simple-context-menu
            :elementId="'driveActions'"
            :options="options"
            :ref="'vueSimpleContextMenu'"
            @option-clicked="optionClicked"
        />
        <fileRename :data="$root.currentItem"></fileRename>
        <folderRename :data="$root.currentItem"></folderRename>
        <folderAdd :data="$root.currentItem"></folderAdd>
        <urlAdd :data="$root.currentItem"></urlAdd>
        <urlRename :data="$root.currentItem"></urlRename>
        <access :data="$root.currentItem"></access>        
        <shortcode :data="$root.currentItem"></shortcode>    
        <versions :data="$root.currentItem"></versions>    
        <itemMove :data="$root.currentItem"></itemMove> 
        <fileLink :data="$root.currentItem"></fileLink> 
        <urlLink :data="$root.currentItem"></urlLink> 
    </div>
</template>

<script>
import fileRename from './file/rename.vue';
import fileLink from './file/link.vue';
import folderRename from './folder/rename.vue';
import folderAdd from './folder/add.vue';
import urlAdd from './url/add.vue';
import urlRename from './url/rename.vue';
import urlLink from './url/link.vue';
import access from './access.vue';
import accessIcon from './access.icon.vue';
import shortcode from './shortcode.vue';
import versions from './file/versions.vue';
import itemMove from './item.move.vue';
import { Modal } from 'bootstrap';
export default {
    props: ['data', 'filter'],
    components: {        
        fileRename: fileRename,
        fileLink: fileLink,
        folderRename: folderRename,
        folderAdd: folderAdd,
        urlAdd: urlAdd,
        urlRename: urlRename,
        urlLink: urlLink,
        access: access,
        accessIcon: accessIcon,
        shortcode: shortcode,
        versions: versions,
        itemMove: itemMove      
    },
    data() {
        return {                   
            options: [],
            shiftStart: false,
            page: 0,
            modalFileRename: null,
            modalFolderRename: null,
            modalLinkRename: null,
            modalLinkAdd: null,
            modalFolderAdd: null,
            modalAccess: null,
            modalMove: null,
            modalFileVersions: null,
            modalShortcode: null,
            modalFileLink: null,
            modalUrlLink: null,
            linkOptions: [                
                {                    
                    name: '<i class="fad fa-fw fa-lg fa-pencil-alt"></i> Modify',
                    slug: 'link-rename'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-arrow-alt-square-right"></i> Move',
                    slug: 'move'
                },
              /*  {
                    name: '<i class="fad fa-fw fa-lg fa-lock-alt"></i> Restrict access',
                    slug: 'access'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-brackets"></i> Get shortcode',
                    slug: 'shortcode'
                }, */
                {
                    name: '<i class="fad fa-fw fa-lg fa-link"></i> Get link',
                    slug: 'url-link'
                },
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-alt"></i> Send to trash',
                    slug: 'link-remove'
                }
            ],
            fileOptions: [
                {                    
                    name: '<i class="fad fa-fw fa-lg fa-pencil-alt"></i> Rename',
                    slug: 'file-rename'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-arrow-alt-square-right"></i> Move',
                    slug: 'move'
                },
              /*  {
                    name: '<i class="fad fa-fw fa-lg fa-lock-alt"></i> Restrict access',
                    slug: 'access'
                }, */
                {
                    name: '<i class="fad fa-fw fa-lg fa-history"></i> Manage versions',
                    slug: 'versions'
                },
            /*    {
                    name: '<i class="fad fa-fw fa-lg fa-brackets"></i> Get shortcode',
                    slug: 'shortcode'
                }, */
                {
                    name: '<i class="fad fa-fw fa-lg fa-link"></i> Get link',
                    slug: 'file-link'
                },
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-alt"></i> Send to trash',
                    slug: 'file-remove'
                }
            ],
            folderOptions: [
                {
                    name: '<i class="fad fa-fw fa-lg fa-pencil-alt"></i> Rename',
                    slug: 'folder-rename'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-arrow-alt-square-right"></i> Move',
                    slug: 'move'
                },
              /*  {
                    name: '<i class="fad fa-fw fa-lg fa-lock-alt"></i> Restrict access',
                    slug: 'access'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-brackets"></i> Get shortcode',
                    slug: 'shortcode'
                }, */
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-alt"></i> Send to trash',
                    slug: 'folder-remove'
                }
            ],
            multiOptions: [                
                {
                    name: '<i class="fad fa-fw fa-lg fa-arrow-alt-square-right"></i> Move',
                    slug: 'move'
                },
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-alt"></i> Send to trash',
                    slug: 'multi-remove'
                }
            ]               
        }
    },    
    methods: {
        dblClick: function(e, item) {
            var _this = this;
            switch (item.mimeType) {
                case 'application/vnd.google-apps.folder':
                    this.$router.push({name: 'adminDocuments', params: {id: item.id}});
                break;
                case 'text/x-uri':
                    window.open(item.description);
                break;
                default: 
                    var xhr = new XMLHttpRequest();
                    var params = "id="+item.id+"&action=download";
                    var url = this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.get.php';
                    var fn = item.name.split(".");
                    var n = item.name;
                    if (fn.length > 0) {
                        var ext = fn.pop();
                        if (item.fullFileExtension.toUpperCase() != ext.toUpperCase()) {
                            n = item.name + '.' + item.fullFileExtension;
                        }
                    }
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {				
                        var blob = xhr.response;				
                        var a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);                        
                        a.download = n;
                        a.dispatchEvent(new MouseEvent('click'));	                        		
                    };							
                    xhr.open('POST', url, true);  
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');          
                    xhr.send(params);
                break;
            }            
        },
        handleClick (event, item, key) {                        
            if (event.ctrlKey) {                                      
                const ci = this.$root.currentItems.filter(c =>  {
                    return c.id == item.id;
                });
                if (ci.length > 0) {
                    this.$root.currentItems = this.$root.currentItems.filter(c =>  {
                        return c.id != item.id;
                    });                    
                } else {
                    this.$root.currentItems.push(item);
                }
                this.shiftStart = key;
            } else if (event.shiftKey) {                                
                if (this.shiftStart === false) {
                    this.shiftStart = key;                 
                }
                var end = key; 
                var start = this.shiftStart;
                this.$root.currentItems = [];
                if (end < start) {    
                    [start, end] = [end, start];
                }                
                for (let i = start; i <= end; i++) {
                    this.$root.currentItems.push(this.data[i]);
                }
            } else {
                this.$root.currentItems = [];
                this.$root.currentItems.push(item);                
                this.shiftStart = key;            
            }
            //Fix buggy access rights set up during inherit
            if (!item.properties) {                
                if (item.mimeType == 'application/vnd.google-apps.folder') {
                    item.properties = {access: '', inherit: 'N'};
                } else {
                    item.properties = {access: ''};
                }
            }
            if (item.mimeType == 'application/vnd.google-apps.folder' && !item.properties.inherit) {
                item.properties.inherit = 'N';
            }
            this.$root.currentItem = item;                        
        },
        handleRightClick (event, item, key) {       
            if (!event.ctrlKey && !event.shiftKey) {
                const ci = this.$root.currentItems.filter(c =>  {
                    return c.id == item.id;
                });
                if (ci.length === 0) {
                    this.$root.setCurrent(event);
                    this.handleClick(event, item, key);
                }
            } else {             
                this.$root.setCurrent(event);
                this.handleClick(event, item, key);
            }
            if (this.$root.currentItems.length > 1) {
                this.options = JSON.parse(JSON.stringify(this.multiOptions));
            } else {
                switch (item.mimeType) {
                    case 'application/vnd.google-apps.folder':
                        this.options = JSON.parse(JSON.stringify(this.folderOptions));
                    break;
                    case 'text/x-uri':
                        this.options = JSON.parse(JSON.stringify(this.linkOptions));
                    break;
                    default:
                        this.options = JSON.parse(JSON.stringify(this.fileOptions));
                    break;
                }
            }
            this.$parent.hideContextMenu('trashActions');
            this.$refs.vueSimpleContextMenu.showMenu(event, item);            
        },
        optionClicked (event) {            
            switch (event.option.slug) {
                case 'file-rename':
                    this.modalFileRename.show();                    
                break;
                case 'file-link':
                    this.modalFileLink.show();                    
                break;
                case 'url-link':
                    this.modalUrlLink.show();                                    
                break;
                case 'folder-rename':
                    this.modalFolderRename.show();                    
                break;
                case 'link-rename':
                    this.modalLinkRename.show();                    
                break;
                case 'folder-add':
                    this.modalFolderAdd.show();                    
                break;
                case 'access':
                    this.modalAccess.show();                    
                break;
                case 'move':
                    this.$root.$emit('reset-move', this.$root.currentItem);
                    this.modalMove.show();                    
                break;
                case 'versions':
                    this.$root.$emit('get-versions', this.$root.currentItem.id);
                    this.modalFileVersions.show();                    
                break;
                case 'file-remove':
                case 'link-remove':
                    this.trashFile(this.$root.currentItem.id);
                break;
                case 'folder-remove':
                    this.trashFolder(this.$root.currentItem.id);
                break;
                case 'multi-remove':
                    this.trashItems();
                break;
                case 'shortcode':
                    this.modalShortcode.show();                    
                break;                
            }            
        },
        trashItems: function() {
            if (!this.$root.currentItems.length) {
                return;
            }
            let ids = this.$root.currentItems.map(i => {
                return i.id;
            });
            var _this = this;           
            this.$root.processing = true;
            var postData = {
				ids: ids
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/items.delete.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {     
                _this.$root.processing = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'Items removed successfully', 'alert-success');	                     
                    
                    if (_this.$route.name == 'search') {                        
                        _this.$root.searchData = _this.$root.searchData.filter(d => {
                            return !response.data.some(function(r){
                                return d.id === r.id;
                            });                        
                        });  
                    } else {
                        _this.$root.driveData = _this.$root.driveData.filter(d => {
                            return !response.data.some(function(r){
                                return d.id === r.id;
                            });                        
                        });  
                    }
                    _this.$root.getTrash();                                         
                    _this.$root.currentItem = _this.$root.itemDefault;
                    _this.$root.currentItems = [];
                    _this.shiftStart = false;
                     $("li.item-wrapper__item").removeClass("current");
                }
            });                         				
        },
        trashFolder: function(id) {
            var _this = this;
            this.$root.processing = true;
            var postData = {
				id: id,
                action: 'trash'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {   
                _this.$root.processing = false;  
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'Folder removed successfully', 'alert-success');		
                    if (_this.$route.name == 'search') {    
                        var r = JSON.parse(JSON.stringify(_this.$root.searchData.filter(d => { return d.id == id; })[0]));		                    
                        _this.$root.searchData = _this.$root.searchData.filter(i => {
                            return i.id != id;
                        }); 
                    } else {
                        var r = JSON.parse(JSON.stringify(_this.$root.driveData.filter(d => { return d.id == id; })[0]));		                    
                        _this.$root.driveData = _this.$root.driveData.filter(i => {
                            return i.id != id;
                        });                       
                    }
                    _this.$root.trashData.push(r);                    
                    _this.$root.currentItem = _this.$root.itemDefault;    
                     $("li.item-wrapper__item").removeClass("current");                               
				}
			});            
        },
        trashFile: function(id) {           
            var _this = this;
            this.$root.processing = true;
            var postData = {
				id: id,
                action: 'trash'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {   
                _this.$root.processing = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'File removed successfully', 'alert-success');	                   
                    if (_this.$route.name == 'search') {    
                        var r = JSON.parse(JSON.stringify(_this.$root.searchData.filter(d => { return d.id == id; })[0]));		                    
                        _this.$root.searchData = _this.$root.searchData.filter(i => {
                            return i.id != id;
                        }); 
                    } else {
                        var r = JSON.parse(JSON.stringify(_this.$root.driveData.filter(d => { return d.id == id; })[0]));		                    
                        _this.$root.driveData = _this.$root.driveData.filter(i => {
                            return i.id != id;
                        });                       
                    }
                    _this.$root.trashData.push(r);  
                    _this.$root.currentItem = _this.$root.itemDefault;    
                     $("li.item-wrapper__item").removeClass("current");       
				}
			});            
        }
    },
    watch: {
        '$root.currentItems': function(val) {
            $("li.item-wrapper__item").removeClass("current");
            for (let i = 0; i < val.length; i++) {             
                $("#"+val[i].id).addClass("current");
            }             
        }
    },
    mounted: function() {
        this.modalFileRename = new Modal(document.getElementById('modalFileRename'));        
        this.modalFolderRename = new Modal(document.getElementById('modalFolderRename'));
        this.modalLinkRename = new Modal(document.getElementById('modalLinkRename'));
        this.modalLinkAdd = new Modal(document.getElementById('modalLinkAdd'));
        this.modalFolderAdd = new Modal(document.getElementById('modalFolderAdd'));
        this.modalAccess = new Modal(document.getElementById('modalAccess'));
        this.modalMove = new Modal(document.getElementById('modalMove'));                    
        this.modalFileVersions = new Modal(document.getElementById('modalFileVersions'));
        this.modalShortcode = new Modal(document.getElementById('modalShortcode'));                              
        this.modalFileLink = new Modal(document.getElementById('modalFileLink'));     
        this.modalUrlLink = new Modal(document.getElementById('modalUrlLink'));     
    }
}
</script>
<style scoped>
ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
li {    
    border-bottom: 1px solid #DDD;
    padding: 8px;
    cursor: pointer;
}
</style>
<style>
ul#driveActions {
    width: 400px;
    background-color: #FFF;
    box-shadow: 0 2px 10px 0 rgb(51 51 51 / 50%);
}
ul#driveActions .vue-simple-context-menu__divider {
    height: 1px;
}
</style>