<template>
    <div class="modal fade" id="modalLinkAdd" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="addLinkLabel" aria-hidden="true">    
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-pencil-alt text-primary"></i> Add a link</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">
                    <!-- Error msg-->
                    <div v-if="addLinkWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{addLinkWarning.message}}		
                        <button class="btn btn-sm btn-default pull-right" v-on:click="addLinkWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="linkName"  placeholder="Link title">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <input type="text" class="form-control" v-model="linkUrl"  placeholder="Link URL (http://example.com)">
                        </div>
                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary"  v-on:click="cancel()">Cancel</button>
                    <button :disabled="linkName.trim() == '' || linkUrl.trim() == '' || loading" class="btn btn-primary" type="button" v-on:click="addLink()">Ok</button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {
        
    },
    data: function() {
        return {
            linkName: '',
            linkUrl: '',
            loading: false,
            addLinkWarning: {'visible': false, 'message': ''}
        }
    },
    methods: {
        cancel: function() {
            this.$parent.modalLinkAdd.hide();      
        },
        addLink: function() {
            this.loading = true;
            var _this = this;
            var postData = {
				parent: this.$root.currentFolder.id,
                action: 'add',
                name: this.linkName.trim(),
                url: this.linkUrl.trim()			
			};
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/url.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                  
                _this.loading = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {      
                    _this.$root.driveData.unshift(response.data);                    
	                _this.cancel();
                    _this.$root.setAlert(true, 'Link added successfully', 'alert-success');					
				}
			});

        }       
    }
}
</script>