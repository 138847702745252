<template>
    <div class="modal fade" id="modalShortcode" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="shortcodeLabel" aria-hidden="true">    
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-brackets text-primary"></i> Shortcode</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">                                                    
                    <div class="row">
                        <div v-if="$root.currentItem.mimeType == 'application/vnd.google-apps.folder'" class="col-12">
                            <div class="row">
                                <div class="col-5">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" :disabled="options.onlyContents" type="checkbox" id="expanded" v-model="options.expanded">
                                        <label class="form-check-label" for="expanded">Expand folder contents by default</label>
                                    </div>  
                                </div>
                                <div class="col-7">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="onlyContents" v-on:change="testExpanded()" v-model="options.onlyContents">
                                        <label class="form-check-label" for="onlyContents">Show only the folder contents, not the parent folder</label>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <textarea class="form-control" rows="3" :value="text">                               
                            </textarea>
                        </div>
                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary" v-on:click="cancel()">Close</button>
                    <button class="btn btn-primary" type="button" v-on:click="doCopy()">Copy</button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {        
        
    },
    data: function() {
        return {           
            loading: false,  
            text: '',
            options: {expanded: false, onlyContents: false},            
            modal: null                  
        }
    },
    methods: {
        testExpanded: function() {
            if (this.options.onlyContents) {
                this.options.expanded = true;
            }
        },
        cancel: function() {
            this.options = {expanded: false, onlyContents: false};
            this.$parent.modalShortcode.hide();                
        },
        doCopy: function() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;          
            clipboardData.writeText(this.text);
            this.cancel();
            this.$root.setAlert(true, 'Shortcode copied to clipboard', 'alert-success');		            
        }
    },
    watch: {
        '$root.currentItem.id': function() {
            var expanded = 'N';
            var onlyContents = 'Y';
            if (this.options.expanded) {
                expanded = 'Y';
            }
            if (!this.options.onlyContents) {
                onlyContents = 'N';
            }
            if (this.$root.currentItem.mimeType == 'application/vnd.google-apps.folder') {
                this.text = '<!-- wp:shortcode -->\r\n[ncsuas_dm_display onlyContents="'+onlyContents+'" expanded="'+expanded+'" itemid="'+this.$root.currentItem.id+'"]\r\n<!-- /wp:shortcode -->';
            } else {
                this.text = '<!-- wp:shortcode -->\r\n[ncsuas_dm_display itemid="'+this.$root.currentItem.id+'"]\r\n<!-- /wp:shortcode -->';
            }
        },
        options: {
            deep: true,
            handler() {
                var expanded = 'N';
                var onlyContents = 'Y';
                if (this.options.expanded) {
                    expanded = 'Y';
                }
                if (!this.options.onlyContents) {
                    onlyContents = 'N';
                }
                if (this.$root.currentItem.mimeType == 'application/vnd.google-apps.folder') {
                    this.text = '<!-- wp:shortcode -->\r\n[ncsuas_dm_display onlyContents="'+onlyContents+'" expanded="'+expanded+'" itemid="'+this.$root.currentItem.id+'"]\r\n<!-- /wp:shortcode -->';
                } else {
                    this.text = '<!-- wp:shortcode -->\r\n[ncsuas_dm_display itemid="'+this.$root.currentItem.id+'"]\r\n<!-- /wp:shortcode -->';
                }
            }
        }
    }
}
</script>