<template>
    <span v-if="data && data.access > ''">
        <i class="fad fa-lock"></i>
    </span>    
</template>
<script>
export default {    
    props: ['data']
}
</script>
