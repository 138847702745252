<template>    
	<div class="container"  style="padding-bottom: 80px">
		<div class="row pb-2 h-100">
			<div class="col border-bottom">		
				<h3 class="display-5"><i class="text-primary fa-xs fad" :class="{'fa-trash-alt': !loading, 'fa-circle-notch fa-spin': loading}"></i> Trashed items</h3>
			</div>			
		</div>
		<div>					
			<!-- Trash -->
			<div>
				<trash-list :data="$root.trashData"></trash-list>
			</div>
		</div>

	</div>  
</template>

<script>
import trashList from './drive/trash.list.vue';

export default {
	components: {			
		trashList		
	},

	data: function() {
    	return {	
			loading: false
		}
	},
	methods: {	
		hideContextMenu(id) {
      		const element = document.getElementById(id);
      		if (element) {
        		element.classList.remove("vue-simple-context-menu--active");        		
      		}
    	},										
    	setAlert: function(display, message, css, icon) {
			this.$root.setAlert(display, message, css, icon);			
		},
		clearAlert: function() {
			this.$root.clearAlert();
		},		
				
	},
	computed: {		
	},
	mounted() {
		
	},
	watch: {					
	}
}
</script>
<style>
.form-label-group .input-group-text input {
	height: inherit;
	padding-left: 5px !important;
	padding-top: 13px !important;
}

.custom-control-label {
	padding-left: .25rem;
	padding-top: 0.1rem;
}
.custom-control-label::before, 
.custom-control-label::after {  
  width: 1.25rem;
	height: 1.25rem;	
}

@keyframes editing {
 0%  {margin-left: 0px;}
 100% {margin-left: 20px;}
}

@keyframes subdued {
 0%  {margin-left: 20px;}
 100% {margin-left: 0px;}
}

.display-5 {
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}
</style>