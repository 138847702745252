<template>
    <div class="modal fade" id="modalUrlLink" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="urlLinkLabel" aria-hidden="true">    
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-link text-primary"></i> Direct URL Link</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">                                                    
                    <div class="row">                        
                        <div class="col">
                            <textarea class="form-control" rows="3" :value="text">                               
                            </textarea>
                        </div>
                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary" v-on:click="cancel()">Close</button>
                    <button class="btn btn-primary" type="button" v-on:click="doCopy()">Copy</button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {        
        
    },
    data: function() {
        return {           
            loading: false,  
            text: ''            
        }
    },
    methods: {        
        cancel: function() {            
            this.$parent.modalUrlLink.hide();                
        },
        doCopy: function() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;          
            clipboardData.writeText(this.text);
            this.cancel();
            this.$root.setAlert(true, 'URL link copied to clipboard', 'alert-success');		            
        }
    },
    watch: {
        '$root.currentItem.id': function() {           
            this.text = this.$root.currentItem.description;            
        },        
    }
}
</script>