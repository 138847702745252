<template>
    <div id="detail">     
        <div class="container pt-2 pb-2">
            <div v-if="$root.currentItem.id == $config.rootId || !$root.currentItem.id">
                <div class="text-center mt-3 pt-3">
                    <div><i class="fad fa-fw fa-5x text-primary fa-file-search"></i></div>
                    <p class="mt-3">See file, link and folder details here</p>
                </div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col"><h5><span v-html="itemIcon"></span> {{$root.currentItem.name}}</h5></div>
                </div>
                <div class="row border-top pt-2">
                    <div class="col-12">
                        <h6>Item properties</h6>
                    </div>
                    <div class="row">
                        <div class="col-3">Type</div>
                        <div class="col-9">{{itemType}}</div>
                    </div>
                    <div v-if="isFile()" class="row">
                        <div class="col-3">Size</div>
                        <div class="col-9">{{formatBytes($root.currentItem.size)}}</div>
                    </div>
                    <div v-if="$root.currentItem.mimeType == 'text/x-uri'" class="row">
                        <div class="col-3">URL</div>
                        <div class="col-9"><a target="_blank" :href="$root.currentItem.description">{{$root.currentItem.description}}</a></div>
                    </div>
                    <div class="row">
                        <div class="col-3">Modified</div>                        
                        <div class="col-9">{{formatDate($root.currentItem.modifiedTime)}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3">Created</div>
                        <div class="col-9">{{formatDate($root.currentItem.createdTime)}}</div>
                    </div>
                    <!--<div v-if="$route.name == 'userSearchResult'" class="row">
                        <div class="col-3">Location</div>
                        <div class="col-9">                                                                                                          
                            <router-link v-if="$root.currentItem.parentData.id == $config.rootId" tag="a" :to="{name: 'userDocuments'}">{{$root.currentItem.parentData.name}}</router-link> 
                            <router-link v-else tag="a" :to="{name: 'userDocuments', params: {id: $root.currentItem.parentData.id}}">{{$root.currentItem.parentData.name}}</router-link>
                        </div>
                    </div> -->
                </div>    
                <div class="row border-top" v-if="isFile()">
                    <div class="col" v-on:click="$parent.download($root.currentItem)">
                        <button class="btn btn-primary mt-2">
                            <i class="fas" :class="{'fa-circle-notch fa-spin': $root.currentItem.downloading, 'fa-download': !$root.currentItem.downloading}"></i> Download
                        </button>
                    </div>                        
                </div>                
            </div>      
        </div>
    </div>
    
</template>
<script>
export default {
    created() {
       window.addEventListener("resize", this.updateSize);
       this.updateSize();       
    },
     data: function() {
        return {           
            mimeTypes: [
                {description: 'HTML', type: 'text/html'},
                {description: 'HTML (zipped)', type: 'application/zip'},
                {description: 'Plain text', type: 'text/plain'},
                {description: 'Rich text', type: 'application/rtf'},
                {description: 'Open Office doc', type: 'application/vnd.oasis.opendocument.text'},
                {description: 'PDF', type: 'application/pdf'},
                {description: 'MS Word document', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
                {description: 'EPUB', type: 'application/epub+zip'},
                {description: 'MS Excel', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
                {description: 'Open Office sheet', type: 'application/x-vnd.oasis.opendocument.spreadsheet'},
                {description: 'PDF', type: 'application/pdf'},
                {description: 'CSV (first sheet only)', type: 'text/csv'},
                {description: '(sheet only)', type: 'text/tab-separated-values'},
                {description: 'HTML (zipped)', type: 'application/zip'},
                {description: 'JPEG', type: 'image/jpeg'},
                {description: 'PNG', type: 'image/png'},
                {description: 'SVG', type: 'image/svg+xml'},
                {description: 'PDF', type: 'application/pdf'},
                {description: 'MS PowerPoint', type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
                {description: 'Open Office presentation', type: 'application/vnd.oasis.opendocument.presentation'},
                {description: 'PDF', type: 'application/pdf'},
                {description: 'Plain text', type: 'text/plain'},
                {description: 'JSON', type: 'application/vnd.google-apps.script+json'},
                {description: 'Folder', type: 'application/vnd.google-apps.folder'},
                {description: 'URL', type: 'text/x-uri'}
            ]
        }
    },
    methods: {        
        isFile: function() {
            var out = false;
            switch (this.$root.currentItem.mimeType) {
                case 'application/vnd.google-apps.folder':                    
                case 'text/x-uri':                    
                break;
                default: 
                    out = true;
                break;
            } 
            return out;
        },
        updateSize: function() {
            var nav = $("nav.bg-custom").height();
            var foot = $("footer").height();
            var wheight = $(window).height();            
            var detail = $("#detail");
            detail.height(wheight - (nav+foot));            
        },
        formatBytes: function(bytes) {
            if (bytes === 0) return '0 Bytes';
            const decimals = 2;
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        
            const i = Math.floor(Math.log(bytes) / Math.log(k));
        
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        formatDate: function(dateString) {
            let dt = new Date(dateString);
            const m = dt.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});            
            return m;
        },
        formatRights: function(rights) {
            var out = "Anyone can access";                        
            if (!rights) {
                return out;
            }
            var access = [];
            access = rights.split(",").filter(x => {
                return x > '';
            });             
            if (access.length > 0) {
                var arr = [];
                arr = this.$root.accessIdentifiers.map(a => {
                    if (access.includes(a.id)) {
                        return a.name;
                    }
                }).filter(x => { return typeof x === 'string'});             
                out = arr.join("\r\n");
            }
            return out;
        }
    },
    computed: {
        itemType: function() {
            var out = this.$root.currentItem.mimeType;
            let type = this.mimeTypes.filter(t => {
                return t.type == this.$root.currentItem.mimeType;
            });
            if (type.length) {
                out = type[0].description;
            }            
            return out;
        },
        itemIcon: function() {
            var out = '';
            switch (this.$root.currentItem.mimeType) {                            
                case 'text/x-uri':   
                    out = '<i class="fas fa-external-link-square" />';
                break;
                default: 
                    let i = this.$root.currentItem.iconLink.replace("16", "128");
                    out = '<img src="'+i+'" />';
                break;
            }
            return out;
        }        
    }
}
</script>

<style>
    #detail {
        position: absolute;
        top: 56px;
        right: 0px;        
        width: 400px;
        height: 90%;
        border-left: 1px solid #CCC;
        background-color: #FFF;
    }   
    #detail .row {
        margin-top: 5px;
        margin-bottom: 5px;
    } 
    #detail .row img {
        height: 24px;
        vertical-align: -4px;
    }
    @media screen and (max-width: 1199px) {
        #detail {
            width: 300px !important;
        }
    }
    #detail a {
        overflow-wrap: break-word;
    }
</style>