<template>    
	<div class="container" style="padding-bottom: 80px;">
		<div class="row pb-2 h-100">
			<div class="mt-2" v-if="!editing" key="list">
				<button 
					type="button" 
					v-on:click="staffEdit(false)"
					class="btn btn-sm btn-primary">
						<i class="fas fa-user-plus"></i> Add a staff member
				</button>
				<div v-if="!$root.loading && $root.staffData.length">
					<div class="form-row mt-2">			
						<div class="col search input-group form-label-group">
							<input type="text" class="form-control" v-model="search" placeholder="Type a user name to begin searching...">
							<label>Type some staff member information to begin searching...</label>
							<button type="button" :disabled="search == ''" v-on:click="search = ''" class="btn btn-secondary"><i class="fas fa-times"></i> Clear</button>							
						</div>			
					</div>	
					<div class="card mb-3">
						<ul class="list-group list-group-flush">
							<li class="list-group-item" :class="{'sales': n.properties.role == 'sales'}" :style="{'border-left-color': '#'+n.properties.color}" v-for="n in paginatedData"> 
								<div class="float-start">
									<span class="h4 long-name mb-1" v-html="$options.filters.highlight(n.description, search)"></span>	
									<span class="ms-2 badge badge-pill bg-info" v-if="n.properties.active == 'no'"> <i class="fa fa-bell" aria-hidden="true"></i> Disabled</span>						
									<span class="ms-2 badge badge-pill bg-info" v-if="n.properties.admin == 'yes'"> <i class="fa fa-bell" aria-hidden="true"></i> Advisorlink admin</span>														
									<div class="row mt-1 mb-1" style="min-width: 700px">
										<div class="col-6">
											<div class="detail"><span class="text-secondary">Username:</span> <span class="text-info" v-html="$options.filters.highlight(n.name, search)"></span></div>
											<div class="detail"><span class="text-secondary">Role:</span> <span class="text-info" v-html="$options.filters.highlight(n.properties.role, search)"></span></div>									
										</div>
										<div class="col-6">
											<div class="detail"><span class="text-secondary">Email:</span> <span class="text-info" v-html="$options.filters.highlight(n.properties.email, search)"></span></div>
											<div class="detail"><span class="text-secondary">Telephone:</span> <span class="text-info" v-html="$options.filters.highlight(n.properties.telephone, search)"></span></div>									
										</div>
									</div>            									
								</div>
								<div class="float-end">
									<div class="text-end">
										<button 
											type="button"
											v-on:click="staffEdit(n);" 
											:disabled="deleting"
											class="btn btn-sm btn-success me-1"                                
										><i class="fas fa-pencil-alt"></i> Edit</button>
										<button 
											type="button"
											v-on:click="n.deleting = true;"
											class="btn btn-sm btn-danger me-2"
											:disabled="n.deleting || deleting"
										><i class="fas fa-trash"></i> Delete</button>                                               
									</div>
									<div class="float-end pt-2" v-show="n.deleting">
											<span class="text-danger">Are you sure?</span>  
											<button type="button" :disabled="deleting" v-on:click="staffDelete(n)" class="btn btn-sm btn-success ms-1 me-1"><i class="fas fa-check"></i> Yes</button>
											<button type="button" :disabled="deleting" v-on:click="n.deleting = false;" class="btn btn-sm btn-danger me-2"><i class="fas fa-times"></i> No</button>
									</div>
								</div>								
							</li>                                
						</ul>
						<div class="card-footer">
							<div class="row">
								<div class="col-2">
									<button class="btn btn-primary" type="button" :disabled="pageNumber == 0" v-on:click="prevPage()"><i class="fas fa-chevron-left"></i> Back</button>
								</div>
								<div class="col-8 mt-2" style="text-align: center">
									<span v-if="pageCount > 0">page {{pageNumber + 1}} of {{pageCount}}</span>
									<span v-else>Your search returned no results</span>
								</div>
								<div class="col">
									<button class="float-end btn btn-primary" type="button" :disabled="pageNumber == pageCount - 1 || pageCount == 0" v-on:click="nextPage()">Next <i class="fas fa-chevron-right"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!$root.loading && !$root.staffData.length" class="border-top mt-2 pt-2">
					<h3><i class="text-primary far fa-square"></i> <span>No staff members exist, yet.</span></h3>
				</div>
				<div v-if="$root.loading" class="border-top mt-2 pt-2">
					<h2><i class="text-primary fas fa-circle-notch fa-spin"></i> Loading...</h2>	
				</div>							
			</div>
			<div v-if="editing" key="alias">
				<staff-edit></staff-edit>				
			</div>
		</div>	        
	</div>  
</template>

<script>
const defaultState = {
	id: "",  
  	name: "",
  	description: "",
  	properties: {
    	type: "staff",
    	password: "",
    	active: "no",
        email: "",
        telephone: "",
        role: "sales",
        admin: "no",
        color: ""
  	},
  	external: {
    	logo: ""
  	}
};

import staffEdit from './staff/edit.vue';
import { staffState } from './staff/staff.state.js';
import { Modal } from 'bootstrap';

export default {
	components: {					
        staffEdit: staffEdit
	},

	data: function() {
    	return {	
			staffState,            
            editing: false,				
			allStaff: [],	
			staff: null,		            
            search: '',
			loading: false,
			deleting: false, //Global deleting indicator
			size: 5, //Elements per page
            pageNumber: 0 //Current page number
		}
	},
	methods: {			
		staffEdit: function(staff) {				
			if (staff) {
				this.loading = true;
				var _this = this;
				this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.get.php', {params: {id: staff.id, action: 'read'}})
            	.then(response => {
					if (response.data.error) {
						_this.loading = false;
						_this.$root.setAlert(true, response.data.error, 'alert-danger');              
						return;
					} else {						
						_this.staffState.id = staff.id;
						_this.staffState.name = staff.name;
						_this.staffState.description = staff.description;
						_this.staffState.properties = staff.properties;
						_this.staffState.external = response.data;
						_this.staff = JSON.parse(JSON.stringify(_this.staffState));
						_this.loading = false;
					}
	            });	
			} else {								
				this.staffState = JSON.parse(JSON.stringify(defaultState));
				this.staff = JSON.parse(JSON.stringify(defaultState));
				this.loading = false;
			}
			this.editing = true;			
		},
		staffDelete: function(staff) {
			var _this = this;	
			this.deleting = true;		
			var postData = {
				type: 'staff',
				action: 'delete',
				data: {
					id: staff.id
				}		
			};
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/user.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {     
				if (response.data.error) {	
					_this.deleting = false;		
					_this.$root.setAlert(true, response.data.error, 'alert-danger');						
					return;
				} else {
					_this.$root.staffData = _this.$root.staffData.filter(u => { return u.id != staff.id });
					_this.staffState = JSON.parse(JSON.stringify(defaultState));
					_this.deleting = false;
					_this.$root.setAlert(true, 'Steff member deleted successfully', 'alert-success');						
				}
			}); 
		},
        nextPage: function() {
            if (this.pageNumber < this.pageCount) {
                this.pageNumber++;
            }
        },
        prevPage: function() {
            if (this.pageNumber > 0) {
                this.pageNumber--;
            }
        },
        setStaff: function(data) {
            this.currentStaff = data;
        },
		hideContextMenu(id) {
      		const element = document.getElementById(id);
      		if (element) {
        		element.classList.remove("vue-simple-context-menu--active");
        		//this.$emit("menu-closed");
      		}
    	},			
		addDocument: function() {
			$("#fileUpload").trigger("click");			
		},			
		uploadFiles: function() {
			if (this.$refs.fileUpload.files.length > 0) {
				this.$root.processing = true;
				var _this = this;
				var data = {
					parent: this.$root.currentFolder.id,
					action: 'add'				
				};				
				var formData = new FormData();
				formData.append("data", JSON.stringify(data));
				for (var f = 0; f < this.$refs.fileUpload.files.length; f++) {
					formData.append("files[]", this.$refs.fileUpload.files[f]);
				}
				this.axios({
					method: 'post'
					,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
					,headers: {'Content-Type': 'multipart/form-data'}				
					,data: formData
				})
				.then(function(response) {							
					_this.$root.processing = false;
					if (response.data.error) {	
						_this.$root.setAlert(true, response.data.error, 'alert-danger');									
						return;
					} else {     						        
						for (let i = 0; i < response.data.length; i++) {
							_this.$root.driveData.unshift(response.data[i])
						}						
						_this.$root.setAlert(true, 'Files added successfully', 'alert-success');					
					}
				});
			}	
		},
    	setAlert: function(display, message, css, icon) {
			this.$root.setAlert(display, message, css, icon);			
		},
		clearAlert: function() {
			this.$root.clearAlert();
		},		
				
	},
	computed: {		
        filtered: function() {
            var s = this.search.toLowerCase();
            return this.$root.staffData.filter(a => {                
               return a.name.toLowerCase().includes(s) ||
               a.description.toLowerCase().includes(s) || 
			   a.properties.role.toLowerCase().includes(s) ||
               a.properties.email.toLowerCase().includes(s) ||
               a.properties.telephone.toLowerCase().includes(s);
            });
        },
        pageCount: function() {
            let l = this.filtered.length;
            let s = this.size;
            var c = Math.ceil(l/s);            
            return c;
        },
        paginatedData: function() {            
            if (this.pageNumber >= this.pageCount) {                
                this.pageNumber = 0;
            }
            const start = this.pageNumber * this.size;            
            const end = start + this.size;
            return this.filtered.slice(start, end);
        } 
	},
	mounted() {
		this.$root.getUsers();
        //this.modalUserEdit = new Modal(document.getElementById('modalUserEdit'));
	},
	watch: {	
		'$route': function(newVal, oldVal) {			
			this.$root.getUsers();
		}				
	}
}
</script>
<style>
#fileUpload, #fileUpdate {
	display: none;
}
.form-label-group .input-group-text input {
	height: inherit;
	padding-left: 5px !important;
	padding-top: 13px !important;
}

.custom-control-label {
	padding-left: .25rem;
	padding-top: 0.1rem;
}
.custom-control-label::before, 
.custom-control-label::after {  
  width: 1.25rem;
	height: 1.25rem;	
}

@keyframes editing {
 0%  {margin-left: 0px;}
 100% {margin-left: 20px;}
}

@keyframes subdued {
 0%  {margin-left: 20px;}
 100% {margin-left: 0px;}
}

.display-5 {
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-group-item.sales {
	border-left-style: solid;
	border-left-width: 5px;
}
</style>