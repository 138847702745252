<template>
    <div>   
        <div class="alert alert-secondary row p-2 mb-1">
            <div class="col-8 pt-1">Items in trash are deleted forever after 30 days.</div>            
            <div class="col-4 text-end">
                <button :disabled="$root.trashData.length == 0" type="button" v-on:click="emptyTrash()" class="btn btn-sm btn-primary"><i class="far fa-trash"></i> Empty trash</button>
            </div>
        </div>
        <ul v-if="data.length > 0">
            <li                                 
                v-on:contextmenu.prevent.stop="handleClick($event, d)"                 
                class="item-wrapper__item" 
                :key="k" 
                v-for="(d, k) in data">
                <span>  
                    <span v-if="d.mimeType == 'application/vnd.google-apps.folder'" class="folder"><img :src="d.iconLink" /> {{d.name}}</span>      
                    <span  v-else-if="d.mimeType == 'text/x-uri'" class="link"><i class="fas fa-external-link-square" /> {{d.name}}</span>   
                    <span v-else class="file"><img :src="d.iconLink" /> {{d.name}}</span>                        
                </span>
            </li>
        </ul>          
        <div v-else>
            <h5><i class="far fa-trash"></i> There are no items in the trash</h5>
        </div>
        <vue-simple-context-menu
            :elementId="'trashActions'"
            :options="options"
            :ref="'vueSimpleContextMenuTrash'"
            @option-clicked="optionClicked"
        />
    </div>
</template>

<script>
export default {
    props: ['data'],
    components: {
        
    },
    data() {
        return {                                      
            options: [],
            shiftStart: false,
            fileOptions: [
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-undo-alt"></i> Restore file',
                    slug: 'file-restore'
                },
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-minus-circle"></i> Delete permanently',
                    slug: 'file-delete'
                }
            ],
            folderOptions: [
                {
                    name: '<i class="fad fa-fw fa-lg fa-trash-undo-alt"></i> Restore folder',
                    slug: 'folder-restore'
                },                
                {
                    type: 'divider'
                },
                {
                    name: '<i class="fad fa-fw fa-lg fa-minus-circle"></i> Delete permanently',
                    slug: 'folder-delete'
                }
            ]            
        }
    },    
    methods: {        
        handleClick (event, item) {
            this.$root.setCurrent(event);
            this.$root.currentItem = item; 
            switch (item.mimeType) {
                case 'application/vnd.google-apps.folder':
                    this.options = JSON.parse(JSON.stringify(this.folderOptions));
                break;
                default:
                    this.options = JSON.parse(JSON.stringify(this.fileOptions));
                break;
            }
            this.$parent.hideContextMenu('driveActions');
            this.$refs.vueSimpleContextMenuTrash.showMenu(event, item);
        },
        optionClicked (event) {
            switch (event.option.slug) {
                case 'file-restore':
                   this.restoreFile(this.$root.currentItem.id);
                break;
                case 'folder-restore':
                    this.restoreFolder(this.$root.currentItem.id);
                break;
                case 'file-delete':
                    this.deleteFile(this.$root.currentItem.id);
                break;
                case 'folder-delete':
                    this.deleteFolder(this.$root.currentItem.id);
                break;
            }
            //window.alert(JSON.stringify(event.option))
        },
        emptyTrash: function() {
            var _this = this;
            this.$parent.loading = true;
			this.axios(_this.$config[process.env.VUE_APP_ENV].app.base+'data/php/trash.empty.php')
			.then(function(response) {                                                							            
                _this.$parent.loading = false;
                if (response.data.error) {	
                    _this.$root.setAlert(true, response.data.error, 'alert-danger');									
                    return;
                } else {                                        					
                    _this.$root.trashData = [];
                    _this.$root.setAlert(true, 'All trashed items deleted', 'alert-success');	
                }
			});             
        },
        restoreFolder: function(id) {
            var _this = this;
            var postData = {
				id: id,
                action: 'restore'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                                                							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'Folder restored successfully', 'alert-success');	
                    var r = JSON.parse(JSON.stringify(_this.$root.trashData.filter(d => { return d.id == id; })[0]));
                    _this.$root.driveData.push(r);
                    _this.$root.trashData = _this.$root.trashData.filter(d => {
                        return d.id != id;
                    });
                    _this.$root.currentItem = _this.$root.itemDefault;                    
				}
			});            
        },
        restoreFile: function(id) {
            var _this = this;
            var postData = {
				id: id,
                action: 'restore'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                                             							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'File restored successfully', 'alert-success');	
                     var r = JSON.parse(JSON.stringify(_this.$root.trashData.filter(d => { return d.id == id; })[0]));
                    _this.$root.driveData.push(r);				
                    _this.$root.trashData = _this.$root.trashData.filter(d => {
                        return d.id != id;
                    });
                    _this.$root.currentItem = _this.$root.itemDefault;           
				}
			});            
        },
        deleteFolder: function(id) {
            var _this = this;
            var postData = {
				id: id,
                action: 'delete'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                                            							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'Folder removed successfully', 'alert-success');					
                    _this.$root.trashData = _this.$root.trashData.filter(d => {
                        return d.id != id;
                    });
                    _this.$root.currentItem = _this.$root.itemDefault;                    
				}
			});            
        },
        deleteFile: function(id) {
            var _this = this;
            var postData = {
				id: id,
                action: 'delete'
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                                             							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        					
                    _this.$root.setAlert(true, 'File removed successfully', 'alert-success');					
                    _this.$root.trashData = _this.$root.trashData.filter(d => {
                        return d.id != id;
                    });
                    _this.$root.currentItem = _this.$root.itemDefault;           
				}
			});            
        }
    }    
}
</script>
<style scoped>
ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
li {    
    border-bottom: 1px solid #DDD;
    padding: 8px;
    cursor: pointer;
}
</style>
<style>
ul#trashActions {
    width: 400px;
    background-color: #FFF;
    box-shadow: 0 2px 10px 0 rgb(51 51 51 / 50%);
}
ul#trashActions .vue-simple-context-menu__divider {
    height: 1px;
}
</style>