<template>    
    <div class="pt-2" v-if="$parent.loading">
        <h2><i class="text-primary fas fa-circle-notch fa-spin"></i> Loading...</h2>	
    </div>
    <div v-else>        
        <div class="row border-bottom pt-3 mb-2">            
            <div class="col-9">
                <h3 class="me-2">
                    <i class="text-primary fas fa-pen-square"></i> 
                    <span v-if="!newUser"> Editing account "{{$parent.user.description}}"</span>
                    <span v-else> Creating a new account</span>                    
                </h3>
            </div>
            <div class="col-3">
                <button type="button" v-on:click="resetUser();" class="mt-1 btn btn-sm btn-primary float-end"><i class="fas fa-arrow-left"></i> Back to accounts</button>
            </div>
        </div>
        <div v-if="newUser" class="mb-2 alert alert-warning"><i class="fad fa-lg fa-exclamation-triangle"></i> Please enter and save the account's login information before continuing</div> 
        <div class="accordion" id="accordion">
            <div class="accordion-item">
                <div class="accordion-header" id="section1" :class="{'alert-success': testUpdateS1}">
                    <button class="accordion-button" type="button" v-on:click="collapse('collapseOne', 'section1')">                    
                        <h4><i class="fas fa-fw fa-user"></i> <span>Login information</span></h4>
                    </button>                                        
                </div>
                <div id="collapseOne" data-bs-parent="#accordion" class="collapse show">
                    <div class="accordion-body">
                        <div class="row border-bottom pb-2 mb-2">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 col-form-label">
                                        <label><strong>Login ID</strong></label>
                                    </div>			
                                    <div class="col-8">
                                        <input type="text" v-model="userState.name" class="form-control" placeholder="User name">                         
                                    </div>                         
                                </div>
                                <div class="row" v-if="errors.name">
                                    <div class="col">
                                        <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.name}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 col-form-label">
                                        <label><strong>Password</strong></label>
                                    </div>			                                   			
                                    <div class="col-8">
                                        <input type="text" v-model="password.pass1" class="form-control mb-1" placeholder="New password">   
                                        <input type="text" v-model="password.pass2" class="form-control" placeholder="Verify password">                                                                    
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="errors.properties.password">
                                    <div class="col">
                                        <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.properties.password}}</div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div class="row border-bottom pb-2 mb-2">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 col-form-label">
                                        <label><strong>Display name</strong></label>
                                    </div>			
                                    <div class="col-8">
                                        <input type="text" v-model="userState.description" class="form-control" placeholder="Display name">     
                                    </div>                         
                                </div>
                                <div class="row" v-if="errors.description">
                                    <div class="col">
                                        <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.description}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 col-form-label">
                                        <label><strong>Active?</strong></label>
                                    </div>			
                                    <div class="col-8 pt-2">
                                        <div class="form-check custom-radio form-check-inline">
                                            <input type="radio" v-model="userState.properties.active" value="yes" id="userActiveYes" name="userActive" class="form-check-input">
                                            <label class="form-check-label" for="userActiveYes">Yes</label>
                                        </div>
                                        <div class="form-check custom-radio form-check-inline">
                                            <input type="radio" v-model="userState.properties.active" value="no" id="userActiveNo" name="userActive" class="form-check-input">
                                            <label class="form-check-label" for="userActiveNo">No</label>
                                        </div>                                        
                                    </div>                         
                                </div> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" 
                                    v-on:click="update('section1')" 
                                    :disabled="!testUpdateS1 || saving !== false" 
                                    class="btn btn-success btn-sm float-end"
                                >
                                    <span v-if="saving == 'section1'"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                                    <span v-else><i class="fas fa-check"></i> Save</span>
                                </button>     
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="section2" :class="{'alert-success': testUpdateS2}">
                    <button class="accordion-button collapsed" type="button" v-on:click="collapse('collapseTwo', 'section2')">
                        <h4 :class="{'text-black-50': newUser}"><i class="fad fa-fw fa-user-cog"></i> <span>Account detail</span></h4>
                    </button>                                        
                </div>
                <div id="collapseTwo" data-bs-parent="#accordion" class="collapse">
                    <div class="accordion-body">
                        <div class="row border-bottom pb-2 mb-2">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 col-form-label">
                                        <label><strong>Logo</strong></label>
                                    </div>			
                                    <div class="col-8">
                                        <div class="pt-2 pb-2">
                                            <img v-if="userState.external.logo" class="img-fluid" id="logo-file-img" :src="userState.external.logo">
                                            <span v-else>No logo set</span>
                                        </div>                                               
                                    </div>
                                </div>                                
                                <div class="row">
                                    <div class="col-10">
                                        <input class="form-control" type="file" accept=".bmp,.gif,.jpg,.jpeg,.png" ref="fileUpload" id="logoFile" v-on:change="setFile()">                                        
                                    </div>		     
                                    <div class="col-2">                                        
                                        <button class="btn btn-danger btn-sm mt-1" :disabled="userState.external.logo == ''" v-on:click="userState.external.logo = ''"><i class="fas fa-ban"></i> Clear</button>
                                    </div>               
                                </div> 
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6 col-form-label">
                                        <label><strong>Show Bluerock disclaimer?</strong></label>
                                    </div>			
                                    <div class="col-6 pt-2">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" v-model="userState.properties.bluerock" value="yes" id="userbluerockYes" name="userbluerock" class="form-check-input">
                                            <label class="form-check-label" for="userbluerockYes">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" v-model="userState.properties.bluerock" value="no" id="userbluerockNo" name="userbluerock" class="form-check-input">
                                            <label class="form-check-label" for="userbluerockNo">No</label>
                                        </div>
                                    </div>                         
                                </div> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" 
                                    v-on:click="update('section2')"
                                    :disabled="!testUpdateS2 || saving !== false" 
                                    :class="{'btn-secondary': newUser, 'btn-success': !newUser}" 
                                    class="btn btn-sm float-end" 
                                >
                                    <span v-if="saving == 'section2'"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                                    <span v-else><i class="fas fa-check"></i> Save</span>
                                </button>       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="section3" :class="{'alert-success': testUpdateS3}">
                    <button class="accordion-button collapsed" type="button" v-on:click="collapse('collapseThree', 'section3')">
                        <h4 :class="{'text-black-50': newUser}"><i class="fad fa-fw fa-user-tag"></i> <span>Assigned staff</span></h4>
                    </button>                    
                </div>
                <div id="collapseThree" data-bs-parent="#accordion" class="collapse">
                    <div class="accordion-body">
                        <div class="row border-bottom pb-2 mb-2">
                            <div class="col-6">                                
                                <div class="form-group row">
                                    <div class="col col-form-label">
                                        <label><strong>Account managers</strong></label>
                                    </div>			
                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <div v-if="userState.properties.iam.length > 0" :class="{'border-bottom': k < userState.properties.iam.length - 1}" v-for="(i, k) in userState.properties.iam">
                                            <div class="form-control-plaintext">                                                
                                                {{getStaff(i.id)}}
                                                <button type="button" v-on:click="removeStaff(userState.properties.iam, k)" class="btn btn-danger btn-sm float-end"><i class="fas fa-ban"></i> Remove</button>
                                            </div>
                                        </div>
                                        <div v-if="userState.properties.iam.length == 0">
                                            <div class="form-control-plaintext">No account managers assigned</div>
                                        </div>                               
                                    </div>                                   
                                </div> 
                                <div class="form-group row">
                                    <div class="col">
                                        <div class="input-group border-top pt-2 mt-2">
                                            <select v-model="iamSelect" class="form-select">
                                                <option value="" selected>Choose...</option>
                                                <option v-if="!testStaff(userState.properties.iam, i)" v-for="i in $root.staffData.filter(s => { return s.properties.role == 'iam' })" :value="i">{{i.description}}</option>
                                            </select>                                            
                                            <button :disabled="iamSelect == ''" type="button" v-on:click="addStaff('iam')" class="btn btn-primary btn-sm"><i class="fas fa-user-plus"></i> Add</button>                                            
                                        </div>                                          
                                    </div>
                                </div>      
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <div class="col col-form-label">
                                        <label><strong>Sales staff</strong></label>                    
                                    </div>			                                    
                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <div v-if="userState.properties.sales.length > 0" :class="{'border-bottom': k < userState.properties.sales.length - 1}" v-for="(i, k) in userState.properties.sales">
                                            <div class="form-control-plaintext">
                                                <div class="pb-2">                                                    
                                                    {{getStaff(i.id)}} 
                                                    <button type="button" v-on:click="removeStaff(userState.properties.sales, k)" class="btn btn-danger btn-sm float-end"><i class="fas fa-ban"></i> Remove</button>
                                                </div>
                                                <div>
                                                    <span v-if="i.states.length > 0">{{i.states.join(', ')}}</span>
                                                    <span v-if="i.states.length == 0">No states/regions assigned</span>
                                                    <button type="button" v-on:click="stateEdit(i);" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-target="#stateSelect" class="btn btn-success btn-sm float-end"><i class="fas fa-pencil-alt"></i> Edit</button>
                                                </div>                            
                                            </div>
                                        </div>
                                        <div v-if="userState.properties.sales.length == 0">
                                            <div class="form-control-plaintext">No sales staff assigned</div>
                                        </div>                                   
                                    </div>                         
                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <div class="input-group border-top pt-2 mt-2">
                                            <select v-model="salesSelect" class="form-select">
                                                <option value="" selected>Choose...</option>
                                                <option v-if="!testStaff(userState.properties.sales, j)" v-for="j in $root.staffData.filter(s => { return s.properties.role == 'sales' })"  :value="j">{{j.description}}</option>
                                            </select>
                                            <button :disabled="salesSelect == ''" type="button" v-on:click="addStaff('sales')" class="btn btn-primary btn-sm"><i class="fas fa-user-plus"></i> Add</button>                                            
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" 
                                    v-on:click="update('section3')" 
                                    :disabled="!testUpdateS3 || saving !== false" 
                                    :class="{'btn-secondary': newUser, 'btn-success': !newUser}" 
                                    class="btn btn-sm float-end"
                                >
                                    <span v-if="saving == 'section3'"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                                    <span v-else><i class="fas fa-check"></i> Save</span>
                                </button>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="section4" :class="{'alert-success': testUpdateS4}">
                    <button class="accordion-button collapsed" type="button" v-on:click="collapse('collapseFour', 'section4')">
                        <h4 :class="{'text-black-50': newUser}"><i class="fas fa-fw fa-file-alt"></i> <span>Message</span></h4>
                    </button>                    
                </div>
                <div id="collapseFour" data-bs-parent="#accordion" class="collapse">
                    <div class="accordion-body">
                        <div class="row border-bottom pb-2 mb-2">
                            <div class="col-12">                           
                                <wysiwyg v-model="userState.external.message"></wysiwyg>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" 
                                    v-on:click="update('section4')" 
                                    :disabled="!testUpdateS4 || saving !== false" 
                                    :class="{'btn-secondary': newUser, 'btn-success': !newUser}" 
                                    class="btn btn-sm float-end"
                                >
                                    <span v-if="saving == 'section4'"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                                    <span v-else><i class="fas fa-check"></i> Save</span>
                                </button>    
                            </div>
                        </div>    
                    </div>                                    
                </div>
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="section5" :class="{'alert-success': testUpdateS5}">
                    <button class="accordion-button collapsed" type="button" v-on:click="collapse('collapseFive', 'section5')">
                        <h4 class="float-left" :class="{'text-black-50': newUser}"><i class="fas fa-fw fa-file-search"></i> <span>File access</span></h4>
                    </button>                    
                </div>
                <div id="collapseFive" data-bs-parent="#accordion" class="collapse">                    
                    <div class="accordion-body">                        
                        <div class="border-bottom mb-1 pb-1">
                            <button type="button" v-on:click="userState.external.access = []" :disabled="userState.external.access.length == 0" class="btn btn-sm btn-danger"><i class="fas fa-ban"></i> Clear all access</button>                                
                        </div> 
                        <div class="pb-2">
                            <ol v-if="$root.driveData.length">
                                <li>
                                    <node :parentid="'root'" :node="$root.driveData[0]"></node>			
                                </li>
                            </ol>                        
                            <h6 v-else>Loading...</h6>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="button" 
                                    v-on:click="update('section5')" 
                                    :disabled="!testUpdateS5 || saving !== false" 
                                    :class="{'btn-secondary': newUser, 'btn-success': !newUser}" 
                                    class="btn btn-sm float-end"
                                >
                                    <span v-if="saving == 'section5'"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                                    <span v-else><i class="fas fa-check"></i> Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <state-select :data="activeStaff"></state-select>        
    </div>
</template>
<script>
const uploadDefault = {name: ''};

import stateSelect from './state.select.vue';
import wysiwyg from './wysiwyg.vue';
import node from './node.vue';
import { Collapse } from 'bootstrap';

export default {
	components: {
        stateSelect: stateSelect,
        wysiwyg: wysiwyg,
        node: node
	},
	data: function() {
		return {  
            userState: this.$parent.userState,            
            nodes: [],                                     
            salesSelect: '',
            iamSelect: '',
            activeStaff: {id: '', states: []},
            toUpload: uploadDefault, //Hold the logo file
            uploading: false,
            password: {hidden: true, pass1: '', pass2: ''},
            errors: {name: false, description: false, properties: { password: false, active: "no" }},
            saving: false,
            modal: null
		}
	},
	methods: {	
        collapse: function(section, button) {                        
            if (this.newUser) {
                return;
            }                        
            let bs = document.querySelectorAll('.accordion-button');
            for (let i = 0; i < bs.length; i++) {
                let p = bs[i].closest('.accordion-header').id;                
                if (p != section) {
                    bs[i].classList.add('collapsed');
                }
            }

            let b = document.querySelector('#'+button).children[0];
            if (b.classList.contains('collapsed')) {
                b.classList.remove('collapsed');
            } else {
                b.classList.add('collapsed');
            }
            let c = Collapse.getOrCreateInstance(document.querySelector('#'+section));
            c.toggle();            
        },
        setFile: function() {            
            var _this = this;
            if (this.$refs.fileUpload.files.length > 0) {
                this.toUpload = this.$refs.fileUpload.files[0]; 
                var fr = new FileReader();
                fr.onloadend = function(event) {                    
                    _this.userState.external.logo = event.target.result;
                };
                fr.readAsDataURL(this.toUpload);
                $('.logo-file-label').html(this.toUpload.name);
            } else {
                this.toUpload = uploadDefault;
                $('.logo-file-label').html('Choose a logo file');
            }
        },
        testValid: function(item) {            
            var i = '';
            var update = false;
            var valid = true;
            var error = '';
            var out = {item: '', data: '', valid: true, update: false, error: ''};
            switch (item) {
                case 'name':
                    i = this.userState.name.trim();
                    update = (i > '' && i != this.$parent.user.name);
                    var _this = this;
                    //Look in both user and staff data for a username matching the provided one
                    var exists = this.$root.staffData.some(u => {
                        return u.name.toLowerCase() == i.toLowerCase() && _this.$parent.user.name.toLowerCase() != i.toLowerCase();
                    }) || this.$root.userData.some(u => {
                        return u.name.toLowerCase() == i.toLowerCase() && _this.$parent.user.name.toLowerCase() != i.toLowerCase();
                    });
                    if (exists) {
                        valid = false;
                        error = 'That login ID is being used by another account';                    
                    }                    
                    if (i == '') {
                        valid = false;
                        error = 'Login ID is required';
                    }
                break;
                case 'description':
                    i = this.userState.description.trim();
                    update = (i > '' && i != this.$parent.user.description);
                    if (i == '') {
                        valid = false;
                        error = 'Display name is required';
                    }
                break;
                case 'properties.password':
                    i = this.password.pass2.trim();
                    update = (i > '' && i != this.$parent.user.properties.password && this.password.pass1.trim() == i);                   
                    if (this.userState.properties.password == '' && (i == '' && this.password.pass1.trim() == '')) {
                        valid = false;
                        error = 'Please create a password';
                    } else if (this.password.pass1.trim() != i) {
                        valid = false;
                        error = 'Both passwords must match';
                    }
                break;
                case 'properties.active': 
                    i = this.userState.properties.active;
                    update = (i != this.$parent.user.properties.active);
                break;
                case 'properties.bluerock': 
                    i = this.userState.properties.bluerock;
                    update = (i != this.$parent.user.properties.bluerock);
                break;
                case 'external.logo': 
                    i = this.userState.external.logo;
                    update = (i != this.$parent.user.external.logo);
                break;
                case 'properties.iam':
                    i = this.userState.properties.iam;
                    var a = Object.assign([], i);
                    var b = Object.assign([], this.$parent.user.properties.iam);
                    a.sort();
                    b.sort();
                    if (a.length != b.length) {
                        update = true;
                    } else {
                        for (var j = 0; j < b.length; j++) {
                            if (JSON.stringify(a[j]) != JSON.stringify(b[j])) {
                                update = true;
                                break;                             
                            }
                        }
                    }              
                break;
                case 'properties.sales':
                    i = this.userState.properties.sales;                    
                    var a = Object.assign([], i);
                    var b =  Object.assign([], this.$parent.user.properties.sales);
                    a.sort();
                    b.sort();
                    if (a.length != b.length) {                        
                        update = true;
                    } else {
                        for (var j = 0; j < b.length; j++) {                                                         
                            if (JSON.stringify(a[j]) != JSON.stringify(b[j])) {                                
                                update = true;
                                break;                              
                            }
                        }
                    }                     
                break;
                case 'external.message':
                    i = this.userState.external.message;
                    update = (i != this.$parent.user.external.message);
                break;
                case 'external.access':
                    i = this.userState.external.access;
                    var a = Object.assign([], i);
                    var b =  Object.assign([], this.$parent.user.external.access);
                    a.sort();
                    b.sort();
                    if (a.length != b.length) {                        
                        update = true;
                    } else {
                        for (var j = 0; j < b.length; j++) {                             
                            if (JSON.stringify(a[j]) != JSON.stringify(b[j])) {                                
                                update = true;
                                break;                              
                            }
                        }
                    }  
                break;
            }
            out.item = item;
            out.data = i;
            out.valid = valid;
            out.update = update;
            out.error = error;
            return out;
        },     
        update: function(section) {            
            var error = false;
            var fields = [];
            var toUpdate = [];
            var _this = this;
            this.saving = section; //Update UI
            switch (section) {
                case 'section1':
                    fields.push(this.testValid('name'),this.testValid('description'),this.testValid('properties.password'),this.testValid('properties.active'));                    
                break;
                case 'section2':
                    fields.push(this.testValid('properties.bluerock'));
                break;
                case 'section3':
                    fields.push(this.testValid('properties.iam'),this.testValid('properties.sales'));
                break;
                case 'section4':
                    fields.push(this.testValid('external.message'));
                break;
                case 'section5':
                    fields.push(this.testValid('external.access'));
                break;
            }
            
            for (var i = 0; i < fields.length; i++) {                
                this.errors[fields[i].item] = false;                               
                if (!fields[i].valid) {
                    this.errors[fields[i].item] = fields[i].error;
                    if (fields[i].item == 'name') { //Reset username
                        this.userState.name = this.$parent.user.name;
                    }
                    error = true;   
                    this.saving = false;                 
                } else {                         
                    toUpdate.push(fields[i]);
                }               
            }    
            if (!error) {
                if (this.newUser) { //This is the first user save, create a new user      
                    this.userState.properties.password = this.password.pass2;                      
                    var postData = {
                        type: 'user',
                        action: 'add',
                        data: {
                            name: this.userState.name,
                            description: this.userState.description,
                            properties: this.userState.properties
                        }		
                    };
                    this.axios({
                        method: 'post'
                        ,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/user.update.php'
                        ,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        ,data: $.param(postData)             
                    }).then(function(response) {     
                        if (response.data.error) {	
                            _this.$root.setAlert(true, response.data.error, 'alert-danger');	
                            _this.saving = false; //Cancel saving, re-enable buttons								
                            return;
                        } else {
                            _this.userState.id = response.data.attributes.id;
                            _this.userState.name = response.data.attributes.name;
                            _this.userState.description = response.data.attributes.description;                                    
                            _this.userState.properties = response.data.attributes.properties;  
                            try {
                                _this.userState.properties.iam = JSON.parse(response.data.attributes.properties.iam);
                            } catch (e) {
                                _this.userState.properties.iam = [];
                            }
                            try {
                                _this.userState.properties.sales = JSON.parse(response.data.attributes.properties.sales);
                            } catch (e) {
                                _this.userState.properties.sales = [];
                            }                                    
                            _this.userState.external = response.data.external;
                            _this.$parent.user = JSON.parse(JSON.stringify(_this.userState));
                            _this.$root.setAlert(true, 'User added successfully', 'alert-success');	                            			
                            _this.saving = false; //Finish saving, re-enable buttons
                            _this.password = {hidden: true, pass1: '', pass2: ''}; //Clear password field
                            _this.$root.userData.push(JSON.parse(JSON.stringify(_this.userState)));
                        }
                    }); 
                } else {              
                    var data = {};      
                    switch (section) {
                        case 'section1':
                            data = {
                                id: this.userState.id,
                                name: this.userState.name,
                                description: this.userState.description,
                                properties: {
                                    active: this.userState.properties.active
                                }
                            };                            
                            if (this.password.pass2) {
                                data.properties.password = this.password.pass2;
                            }
                        break;
                        case 'section2':
                            data = {
                                id: this.userState.id,
                                external: {
                                    logo: this.userState.external.logo                                    
                                },
                                properties: {
                                    bluerock: this.userState.properties.bluerock
                                }
                            };
                        break;
                        case 'section3':
                            data = {
                                id: this.userState.id,
                                properties: {
                                    iam: JSON.stringify(this.userState.properties.iam),
                                    sales: JSON.stringify(this.userState.properties.sales)
                                }
                            };                            
                        break;
                        case 'section4':
                            data = {
                                id: this.userState.id,
                                external: {
                                    message: this.userState.external.message
                                }
                            };
                        break;
                        case 'section5':
                            data = {
                                id: this.userState.id,
                                external: {
                                    access: this.userState.external.access
                                }
                            };
                        break;                        
                    };
                    var postData = {
                        type: 'user',
                        action: 'update',
                        data: data			
                    };
                    this.axios({
                        method: 'post'
                        ,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/user.update.php'
                        ,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        ,data: $.param(postData)             
                    }).then(function(response) {                            							            
                        if (response.data.error) {	
                            _this.$root.setAlert(true, response.data.error, 'alert-danger');	
                            _this.saving = false; //Cancel saving, re-enable buttons								
                            return;
                        } else {
                            switch (section) {
                                case 'section1':
                                    _this.userState.name = response.data.attributes.name;
                                    _this.userState.description = response.data.attributes.description;                                    
                                    _this.userState.properties = response.data.attributes.properties;                                    
                                break;
                                case 'section2':
                                    _this.userState.external.logo = response.data.external.logo;
                                    _this.userState.properties.bluerock = response.data.properties.bluerock;
                                break;
                                case 'section3':
                                    try {
                                        _this.userState.properties.iam = JSON.parse(response.data.attributes.properties.iam);
                                    } catch (e) {
                                        _this.userState.properties.iam = [];
                                    }
                                    try {
                                        _this.userState.properties.sales = JSON.parse(response.data.attributes.properties.sales);
                                    } catch (e) {
                                        _this.userState.properties.sales = [];
                                    }                                                                        
                                break;
                                case 'section4':
                                    _this.userState.external.message = response.data.external.message;
                                break;
                                case 'section5':
                                    _this.userState.external.access = response.data.external.access; 
                                break;
                            }
                            _this.$parent.user = JSON.parse(JSON.stringify(_this.userState));                            
                            _this.$root.setAlert(true, 'User updated successfully', 'alert-success');					
                            _this.saving = false; //Finish saving, re-enable buttons
                        }
                    });                    
                }                
            }
        },        
        resetUser: function() {
            this.$parent.editing = false;
        },
        getStaff: function(id) {               
            let staff = this.$root.staffData.find(s => {
                return s.id == id;
            });      
            let out = '';  
            if (staff) {    
                out = staff.description;
            }
            return out;
        },
        addStaff: function(type) {            
            switch (type) {
                case 'iam':
                    this.userState.properties.iam.push({id: this.iamSelect.id});
                    this.iamSelect = '';
                break;
                case 'sales':
                    this.userState.properties.sales.push({id: this.salesSelect.id, states: []});
                    this.salesSelect = '';
                break;
            }
        },
        stateEdit: function(staff) {
            this.activeStaff = staff;            
        },
        removeStaff: function(arr, key) {
            return arr.splice(key, 1);
        },
        testStaff: function(arr, item) {
            if (Array.isArray(arr)) {
                return arr.some(i => {
                    return item.id == i.id;
                });
            } else {
                return false;
            }
        } 
    },
    computed: {
        testUpdateS1: function() {            
           return this.testValid('name').update || this.testValid('description').update || this.testValid('properties.password').update || this.testValid('properties.active').update;
        },
        testUpdateS2: function() {            
           return this.testValid('properties.bluerock').update || this.testValid('external.logo').update;
        },
        testUpdateS3: function() {            
           return this.testValid('properties.iam').update || this.testValid('properties.sales').update;
        },
        testUpdateS4: function() {            
           return this.testValid('external.message').update;
        },
        testUpdateS5: function() {            
           return this.testValid('external.access').update;
        },
        newUser: function() {
            return this.userState.id == '';
        }
    }
}
</script>
<style>
.custom-file-control.selected::after {
    content: "" !important;
}
.form-group {
    margin-bottom: 0;
}
#tree-root {
    margin-top: 0px;
}

.accordion-body {
    padding: 10px;
}

h4 {
    cursor: pointer;
}
.alert {
    margin-bottom: 0;
}

.accordion-header h4 span:hover {
    text-decoration: underline;
}

#tree-root .node {
    padding: 0px 0px 0px 10px;
}

.node > div:hover {
    background-color: #cce5ff;
}

.node > div {
    padding: 5px 0px 5px 5px;
}

ol {
    list-style-type: none;
    margin: 0px !important;
    padding: 0px !important;
}

li {
    margin-end: 0px !important;
    padding-end: 0px !important;
}

</style>