<template>
    <div class="modal fade" id="modalAccess" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="accessLabel" aria-hidden="true">    
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-lock-alt text-primary"></i> Restrict access</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">
                    <!-- Error msg-->
                    <div v-if="accessWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{accessWarning.message}}		
                        <button class="btn btn-sm btn-default pull-right" v-on:click="accessWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->
                    <div class="row">
                        <div :key="k" v-for="(r, k) in $root.accessIdentifiers" class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :value="r.id" :id="r.id" v-model="access">
                                <label class="form-check-label">
                                    {{r.name}}
                                </label>
                            </div>
                        </div>
                    </div>  
                    <div v-if="$root.currentItem.mimeType == 'application/vnd.google-apps.folder'" class="row border-top pt-2 mt-3">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="inherit">
                                <label class="form-check-label">
                                    Apply access settings to all items in this folder
                                </label>
                            </div>
                        </div>
                    </div>          
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
                    <button :disabled="loading" class="btn btn-primary" type="button" v-on:click="updateAccess()">Ok</button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {
        
    },
    data: function() {
        return {
            access: [],
            inherit: "N",
            loading: false,
            accessWarning: {'visible': false, 'message': ''}
        }
    },
    methods: {
        cancel: function() {
            this.$parent.modalAccess.hide();          
        },
        updateAccess: function() {
            this.loading = true;
            var _this = this;
            var url = '';
            switch (this.$root.currentItem.mimeType) {
                case 'application/vnd.google-apps.folder':
                    url = this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php';
                break;
                case 'text/x-uri':
                    url = this.$config[process.env.VUE_APP_ENV].app.base+'data/php/url.update.php';
                break;
                default:
                    url = this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php';
                break;
            }
            var postData = {
				id: this.$root.currentItem.id,
                parent: this.$root.currentFolder.id,
                action: 'access',
                access: this.access,
                inherit: this.inherit
			};                          
			this.axios({
				method: 'post'
				,url: url
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                                          							            
                _this.loading = false;                
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                          
                    _this.$root.currentItem = response.data;
                    console.log(response.data);
                    if (_this.$route.name == 'search') {
                        var item = _this.$root.searchData.filter(f => {
                            return f.id == response.data.id;
                        })[0];                    
                    } else {
                        var item = _this.$root.driveData.filter(f => {
                            return f.id == response.data.id;
                        })[0];                    
                    }
                    item.properties.access = response.data.properties.access;
                    item.properties.inherit = response.data.properties.inherit;     
                    _this.access = response.data.properties.access.split(",");     
	                _this.cancel();
                    _this.$root.setAlert(true, 'Access updated successfully', 'alert-success');					
				}
			});
        }
    },
    watch: {        
        'data': function(newVal) {            
            if (newVal.id && newVal.id != this.$config.rootId) {       
                if (!newVal.properties) {
                    this.access = [];
                    this.inherit = 'N';
                } else {
                    this.access = JSON.parse(JSON.stringify(newVal.properties.access)).split(",").filter(x => {
                        return x > '';
                    });  
                    if (newVal.properties.inherit) {
                        this.inherit = JSON.parse(JSON.stringify(newVal.properties.inherit)) || 'N';
                    } else {
                        this.inherit = 'N';
                    }
                }
            }
        }
    }
}
</script>