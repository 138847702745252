<template>
    <div class="modal fade" id="modalMove" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="moveLabel" aria-hidden="true">    
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad text-primary" :class="{'fa-arrow-alt-square-right': !loading, 'fa-circle-notch fa-spin': loading}"></i> Move</h4>                       
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body pt-1">
                    <!-- Error msg-->
                    <div v-if="moveWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{moveWarning.message}}		
                        <button class="btn btn-sm btn-default pull-right" v-on:click="moveWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->                    
                    <div v-if="!loading">
                        <div class="row">
                            <div class="alert-secondary pt-1 mb-2">
                                <button type="button" class="p-0 pe-2 pb-1 goto btn" :disabled="moveTo == $config.rootId || currentFolder.id == $config.rootId" v-on:click="getFolders(parentFolderId)"><i class="fas fa-arrow-left"></i></button>                                
                                <span class="h5" v-on:click="setActive(currentFolder.id)">{{truncate(currentFolder.name)}}</span>
                            </div>
                        </div>
                        <div class="row" v-if="moveFolders.length > 0">                         
                            <div class="item col-12 mt-1 mb-1 ps-2 pe-2"
                                :class="{'active': d.id == moveTo, 'disabled': d.id == currentItem.id}"
                                :id="d.id"
                                :key="k"   
                                v-on:click="setActive(d.id)"                                                        
                                v-for="(d, k) in moveFolders">
                                <div class="row"> 
                                    <div class="col-10">
                                        <span class="folder"><img style="vertical-align: -2px" :src="d.iconLink" /> {{truncate(d.name)}} <access-icon :data="d.properties"></access-icon></span> 
                                    </div>                                   
                                    <div class="col-2 text-end">
                                        <button v-if="d.id != currentItem.id" class="goto btn p-0" v-on:click="getFolders(d.id)"><i class="fas fa-angle-right"></i></button>
                                        <i v-else class="fas fa-ban"></i>
                                    </div>
                                </div>
                            </div>                                                  
                        </div>  
                        <div class="row" v-else>
                            <div class="col">
                                <div class="d-flex align-items-center justify-content-center" style="height: 300px">
                                    This folder does not contain any sub-folders
                                </div>
                            </div>
                        </div>                             
                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
                    <button :disabled="isDisabled" class="btn btn-primary" type="button" v-on:click="moveItem()">Move here</button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import accessIcon from './access.icon.vue';
export default {    
    components: {
        accessIcon: accessIcon
    },
    props: ['data'],
    created () {
        
    },
    data: function() {
        return {            
            loading: false,
            moveFolders: [],
            currentFolder: {},
            parentFolderId: '',
            currentItem: {},
            moveTo: false,            
            moveWarning: {'visible': false, 'message': ''}
        }
    },
    methods: {
        setActive: function(id) {            
            if (id != this.currentItem.id) {
                this.moveTo = id;
            }
        },
        truncate: function(str) {
            var maxlen = 40;
            var out = str;
            if (str && str.length > maxlen) {                
                let b = str.substring(0, 18);
                let e = '...'+str.substring(str.length - 18, str.length);
                out = b + e;
            }
            return out;
        },
        moveItem: function() {            
            if (!this.$root.currentItems.length) {
                return;
            }
            let ids = this.$root.currentItems.map(i => {
                return i.id;
            });
            var _this = this;
            this.loading = true;
            var postData = {
				ids: ids,
                parent: this.moveTo
			};            
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/items.move.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {                              
                _this.loading = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                                            					
                    _this.$root.setAlert(true, 'Items moved successfully', 'alert-success');	
                    _this.$root.driveData = _this.$root.driveData.filter(d => {
                        return !response.data.some(function(r){
                            return d.id === r.id;
                        });                        
                    });                                                                     
                    _this.$root.currentItem = _this.$root.itemDefault;
                    _this.$root.currentItems = [];
                    _this.shiftStart = false;
                     $("li.item-wrapper__item").removeClass("current");
                    _this.cancel();
                }
            });              
        },
        getFolders: function(id) {      
            var _this = this;
            this.loading = true;
            
            var params = {
                search: '',
                searchType: 'name',
                itemType: 'folder',
                itemLocation: 'documents',
                parent: id
            }     
            
            const folderLookup =  this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/search.php', {params: params});
            const parentLookup = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/breadcrumb.get.php', {params: {id: id}});   

            Promise.all([folderLookup, parentLookup])
            .then(response => {	                                
                for (var d = 0; d < response.length; d++) {
                    if (response[d].data.error) {
                    _this.$root.setAlert(true, response[d].data.error, 'alert-danger');
                    _this.loading = false;
                    return;
                    }
                }                        
                _this.moveFolders = response[0].data;                
                var c = response[1].data[response[1].data.length - 1];
                _this.currentFolder = c;                
                _this.parentFolderId = _this.$config.rootId;
                if (c.id != _this.$config.rootId) {
                    _this.parentFolderId = response[1].data[response[1].data.length - 2].id;
                }
                _this.setActive(id);
                _this.loading = false;						
            }).catch(error => {
                _this.$root.setAlert(true, error, 'alert-danger');                
                _this.loading = false;
                return;
            }); 

        },
        cancel: function() {
            this.$parent.modalMove.hide();            
        },        
    },
    computed: {
        isDisabled: function() {
            var out = false;
            if (this.loading) {
                out = true;
            } else {
                if (this.currentItem.parents && this.currentItem.parents.length > 0) {
                    let p = this.currentItem.parents[0];
                    if (p == this.currentFolder.id || p == this.moveTo) {
                        out = true;
                    }
                }
            }   
            return out;
        }
    },
    mounted() {
        var _this = this;
        //this.$root.$off('reset-move');
        /*
        this.$root.$on('reset-move', function(item) {               
            if (item.parents && item.parents.length) {
                _this.getFolders(item.parents[0]);
                _this.currentItem = item;
            }
        });        
        */
    }
}
</script>
<style scoped>    
    .item:hover {
        cursor: pointer;
    }
    .item.active {
        background-color: #e8f0fe;
    }
    .item.active span  {
    	color: #197cde;
    }
    .item.disabled {
        color: #777;
    }
    .item.disabled:hover {
        cursor: default;        
    }
    .goto:focus,.goto:active {
        outline: none !important;
        box-shadow: none;
    }
    .modal-body {
        min-height: 300px;        
    }
    .folders {
        height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .h5 {
        cursor: pointer;
    }
</style>
