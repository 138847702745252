import { reactive } from 'vue'

export const userState = reactive({
  id: "",  
  name: "",
  description: "",
  properties: {
    type: "user",
    password: "",
    active: "no",
    bluerock: "no",
    iam: "",
    sales: ""
  },
  external: {
    logo: "",    
    message: "",
    access: []
  }
})