<template>
    <div class="row justify-content-center m-0" v-on:keyup.enter="signIn()">
        <div class="card col-md-8 col-lg-5 col-xl-4 col-sm-8 col-10">
            <div class="logo">
                <img src="@/assets/images/logo.png" :alt="$root.$config.title">
            </div>            
            <form class="mt-3 mb-3">
                <div class="row">
                    <div class="col">
                        <div class="form-label-group">
                            <input type="text" name="userName" class="form-control" placeholder="User name" v-model="login.username" />
                            <label>User name</label>						
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-label-group">
                            <input type="password" name="userPassword" class="form-control" placeholder="Password" v-model="login.password" />
                            <label>Password</label>						
                        </div>
                    </div>
                </div>
                <div v-if="error > ''" :class="{'shake': error > ''}" :key="errKey" class="row">
                    <div class="alert col ms-3 me-3 mb-3 alert-danger"><i class="fad fa-lg fa-exclamation-square"></i> {{error}}</div>
                </div>
                <div v-if="$route.params.state == 'logout' && error == '' && !working" class="row">
                    <div class="alert col ms-3 me-3 mb-3 alert-success"><i class="fad fa-lg fa-flag"></i> You have been logged out</div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <button type="button" v-on:click="signIn()" :disabled="working" class="btn btn-lg btn-block btn-dark">
                            <i class="fad" :class="{'fa-circle-notch fa-spin': working, 'fa-sign-in-alt': !working}"></i> <span v-if="working">Please wait</span> <span v-else>Sign in</span>
                        </button>

                    </div>
                </div>
            </form>           
        </div>          
    </div>
</template>
<script>
export default { 
    data: function() {
        return {
            login: {username: '', password: ''},
            error: '',
            errKey: 0,
            working: false     
        }
    },
    methods: {
        signIn: async function() {   
            var _this = this;                         
            this.error = '';
            this.errKey += 1;                              
            this.login.username = this.login.username.trim();
            this.login.password = this.login.password.trim();  

            if (this.login.username == '') {
                this.error = 'Please provide a username';
                return;
            }
            if (this.login.password == '') {
                this.error = 'Please provide a password';
                return;
            }

            this.working = true;  

            var postData = {
				username: this.login.username,
                password: this.login.password				
			};
            this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/login.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {
                if (response.data.error) {	
					_this.working = false;		
					_this.error = response.data.error;
					return;
				} else {
                    _this.working = false;
                    _this.$cookies.set(_this.$config.app_cookie, response.data);
                    _this.$root.user = response.data; 
				}
            })
        }
    },
    created: function() {
        this.$root.user = this.$cookies.get(this.$config.app_cookie);                         
    },
    watch: {
        '$root.user': {
            handler: function() {
                if (this.$root.user != null) {                                                                      
                    switch (this.$root.user.properties.type) {
                        case 'user':
                            this.$router.push({name: 'userHome'});
                        break;
                        case 'staff':
                            this.$router.push({name: 'adminHome'});
                        break;
                    }                    
                }   
            }
        }
    }
}
</script>
<style scoped>
.row {
    background-color: transparent;
}
.card {
    padding-top: 20px;
    margin-top: 20px;
    background-color: rgba(255,255,255, 0.6);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.card .logo {
    margin: 0px auto;
}

.card .logo img {
    width: 100%;
    
}

.shake {
  animation: errShake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes errShake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>