<template>
    <div class="modal fade" id="modalFileVersions" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="fileVersionsLabel" aria-hidden="true">    
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2">
                        <i v-if="deleting || loading" class="fas fa-circle-notch fa-spin"></i>
                        <i v-else class="fad fa-pencil-alt text-primary"></i> 
                        Manage versions</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">
                    <!-- Error msg-->
                    <div v-if="fileVersionsWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{fileVersionsWarning.message}}		
                        <button class="btn btn-sm btn-default float-end" v-on:click="fileVersionsWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->
                    <div class="row">
                        <div class="col">
                             <ul v-if="revisions.length > 0">
                                <li :key="k" class="pb-2 pt-2 border-bottom" v-for="(d, k) in revisions"> 
                                    <div>                                                                           
                                        <span class="file"><i class="fal fa-file-alt"></i> {{d.originalFilename}} </span>    
                                        <div v-if="d.id != data.headRevisionId" class="float-end">                                            
                                            <!--<button type="button" v-on:click="revisionSetCurrent(d)" class="btn btn-sm btn-success me-1"><i class="fas fa-fw fa-check"></i></button>-->
                                            <button type="button" v-on:click="revisionDownload(d)" class="btn btn-sm btn-primary me-1"><i class="fas fa-fw fa-arrow-alt-to-bottom"></i></button>
                                            <button type="button" v-on:click="revisionDelete(d)" class="btn btn-sm btn-danger"><i class="far fa-fw fa-trash-alt"></i></button>
                                        </div>                                           
                                    </div>
                                    <div>
                                        <span class="revision-detail">Uploaded {{formatDate(d.modifiedTime)}}</span>
                                        <div class="float-end badge bg-success" v-if="d.id == data.headRevisionId"><i class="fas fa-fw fa-check"></i> Current revision</div>          
                                    </div>
                                </li>
                            </ul>                            
                        </div>                        
                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" :disabled="uploading || deleting" v-on:click="addRevision()" class="btn btn-primary">
                        <span v-if="uploading">
                            <i class="fas fa-circle-notch fa-spin"></i> Uploading...
                        </span>
                        <span v-else>
                            <i class="fas fa-file-upload"></i> Upload new revision
                        </span>
                    </button>
                    <button type="button" class="btn btn-secondary"  v-on:click="cancel()">Close</button>
                </div>
                <input type="file" ref="fileUpdate" id="fileUpdate" :key="k" v-on:change="uploadFile()">
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {
        
    },
    data: function() {
        return {            
            loading: false,
            revisions: [],
            deleting: false,
            k: 0,
            uploading: false,
            fileVersionsWarning: {'visible': false, 'message': ''}
        }
    },
    methods: {
        revisionSetCurrent: function(r) {

        },
        revisionDelete: function(r) {
            this.deleting = true;
            var _this = this;
            var postData = {
				id: this.data.id,
                revid: r.id,
                action: 'delete'          
			};
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/revision.update.php'				
				,data: $.param(postData)             
			}).then(function(response) {                       
                _this.deleting = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        
					_this.revisions = _this.revisions.filter(rev => {
                        return rev.id != r.id;
                    });           
				}
			});
        },
        revisionDownload: function(r) {
            var xhr = new XMLHttpRequest();
            var params = "id="+this.data.id+"&revid="+r.id+"&action=download";
            var url = this.$config[process.env.VUE_APP_ENV].app.base+'data/php/revision.get.php';
            var n = r.originalFilename;            
            xhr.responseType = 'blob';
            xhr.onload = function(event) {				
                var blob = xhr.response;				
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);                        
                a.download = n;
                a.dispatchEvent(new MouseEvent('click'));	                        		
            };							
            xhr.open('POST', url, true);  
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');          
            xhr.send(params);
        },
        formatDate: function(dateString) {
            let dt = new Date(dateString.replace(/-/g, '\/'));
            const m = dt.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute:'2-digit'});
            return m;
        },
        addRevision: function() {
            $("#fileUpdate").trigger("click");
        },
        getRevisions: function(id) {
            this.loading = true;

            var _this = this;
            var postData = {
				id: id               
			};
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/revisions.get.php'				
				,data: $.param(postData)             
			}).then(function(response) {                                        							            
                _this.loading = false;
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        
					_this.revisions = response.data;                    
				}
			});
        },
        uploadFile: function() {
            if (this.$refs.fileUpdate.files.length > 0) {
                this.k++;
				this.uploading = true;
				var _this = this;
				var data = {
					id: this.data.id,
					action: 'update'				
				};				
				var formData = new FormData();
				formData.append("data", JSON.stringify(data));
				for (var f = 0; f < this.$refs.fileUpdate.files.length; f++) {
					formData.append("files[]", this.$refs.fileUpdate.files[f]);
				}
				this.axios({
					method: 'post'
					,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.update.php'
					,headers: {'Content-Type': 'multipart/form-data'}				
					,data: formData
				})
				.then(function(response) {							
					_this.uploading = false;
					if (response.data.error) {	
						_this.$root.setAlert(true, response.data.error, 'alert-danger');									
						return;
					} else {                          
						for (let i = 0; i < response.data.length; i++) {
							_this.revisions.unshift(response.data[i])
						}
                        _this.$root.currentItem.headRevisionId = response.data[0].id;
						_this.$root.setAlert(true, 'File added successfully', 'alert-success');
					}
				});
			}
        },
        cancel: function() {           
            this.$parent.modalFileVersions.hide(); 
            this.uploading = false;
            this.deleting = false; 
            this.revisions = [];       
        },    
       
    },
    mounted() {
        var _this = this;
        /*
        this.$root.$off('get-versions');
        this.$root.$on('get-versions', function(id) {                        
            _this.getRevisions(id);
        });
        */
    }    
}
</script>
<style scoped>
    ul {
        list-style-type: none;
        padding-left: 0rem;
    }
    .revision-detail {
        font-size: 0.7rem;
    }
    .btn-sm {
        padding: 0.1rem 0.2rem;
    }
    .modal-dialog{
        overflow-y: initial !important
    }
    .modal-body{
        height: 50vh;
        overflow-y: auto;
    }
</style>