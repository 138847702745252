<template>   
    <div class="node">	             				
		<div class="border-bottom">
			<span class="name folder" v-on:click.stop="collapsed = !collapsed" v-if="node.mimeType == 'application/vnd.google-apps.folder'">
				<i class="fa-lg" :class="{'fad fa-folder-open': !collapsed, 'fas fa-folder': collapsed}"></i> {{node.name}}
			</span>
            <span v-else-if="node.mimeType == 'text/x-uri'" class="link">
                <i class="fas fa-external-link-square" /> {{node.name}}
            </span>
            <span v-else class="file">
                <img :src="node.iconLink" /> {{node.name}}
            </span>                 
                        
			<span v-show="hasAccess == 'all'"><i class="text-success fas fa-star"></i></span>
			<span v-show="hasAccess == 'some' || (hasAccess == 'none' && some != 'none')"><i class="text-success fas fa-star-half-alt"></i></span>			
			
            			
			<div class="float-end">
                <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" :key="node.id" :value="node.id" v-model="userState.external.access"  v-on:click.stop="nodeUpdate(node)" :id="'assign_'+node.id">
                    <label class="form-check-label" :for="'assign_'+node.id"></label>
                </div>
			</div>
		</div>	
		<ol v-show="!collapsed">
			<!-- The nodes, activeNode is set on click -->            
			<li v-show="node.children.length > 0" v-for="(n, i) in node.children">
				<node :parentid="node.id" :node="n"></node>		
			</li>			
		</ol>			
	</div>                                      
</template>

<script>
import node from './node.vue';
import { userState } from './user.state.js';
export default {
    name: "node",
    props: {
        node: Object,
        parentid: String
    },
    data() {
        return {   
            userState,      
            count: 0,
            collapsed: true,                      
            some: 'none',
            tempNode: Object.assign({}, this.node)			             
        }
    },      
    methods: {            
        nodeUpdate: function(node) {   
            let ind = userState.external.access.indexOf(node.id);
            let action = null;
            if (ind !== -1) {
                userState.external.access.splice(ind, 1);     
                action = 'revoke';
            } else {
                userState.external.access.push(node.id);
                action = 'grant';
            }            
			if (node.mimeType == 'application/vnd.google-apps.folder') { //Recursively grant access to children			
                this.grantChildAccess(node, action);                            
            }
        },
		grantChildAccess: function(node, action) {					
			for (var i = 0; i < node.children.length; i++) {
                let c = node.children[i];				
                var ind = false;
				if (c.mimeType == 'application/vnd.google-apps.folder') {
					if (c.children.length > 0) {
						this.grantChildAccess(c, action);
					}
				}								
				switch (action) {					
                    case 'grant':						
                        ind = userState.external.access.findIndex(a => {
					        return a == c.id;
                        }); 
                        if (ind == -1) { 
                            userState.external.access.push(c.id);						
                        }
					break;
					case 'revoke':                        
						ind = userState.external.access.findIndex(a => {
					        return a == c.id;
                        }); 
                        if (ind != -1) { 
							userState.external.access.splice(ind, 1);
						}
					break;
                }
                ind = false;
			}			
		},
		bubbleStatus: function(status) {					
			this.some = status;            
			if (this.parentid != 'root') {            
				for (var i = 0; i < this.$parent.node.children.length; i++) {						
					if (this.$parent.node.children[i].some != 'none' && status == 'none') {
						return;
					}
				}
				this.$parent.bubbleStatus(status);
			}
		}       
    },
    computed: {
        hasAccess: function() {			
			var out = '';
			if (this.node.mimeType == 'application/vnd.google-apps.folder' && this.node.children.length) { //Folders with children, we'll check the children to see if they're the same, or different and deal with the parent accordingly
				var children = this.node.children;
				var status = userState.external.access.indexOf(this.node.id);
				if (children.length) {
					//What's the inclusion status of the first child?
					var c1 = userState.external.access.includes(this.node.children[0].id);
					var diff = false;  //Assume everything is the same for now
					for (var i = 0; i < this.node.children.length; i++) {
						//If the inclusion status of any of the other children is different from the first child's inclusion status, break
						if (userState.external.access.includes(this.node.children[i].id) != c1) {
							diff = true;
							break;
						}
					}
					if (diff) { //The inclusion status of the children are different, remove access for the parent
						if (status != -1) {
							userState.external.access.splice(status, 1);
						}											
						out = 'some';
					} else { //The inclusion status of the children are the same
						if (c1) {
							if (status == -1) { //If the parent element doesn't have access, set it as all children have access
                            userState.external.access.push(this.node.id);
							}
							out = 'all';
						} else { //If the parent node has access, remove it as no children have access
							if (status != -1) {
								userState.external.access.splice(status, 1);
							}														
							out = 'none';
						}
					}
				}				
			} else { //Not a folder, either assigned or not
				if (userState.external.access.includes(this.node.id)) {
					out = 'all';
				} else {
					out = 'none';
				}
			}
			this.bubbleStatus(out);
			return out;
		}
    }
}
</script>
<style scoped>
ol {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
li {        
    padding: 0px 8px;    
    cursor: pointer;
}
</style>