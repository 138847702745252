<template>
    <div class="modal fade" id="modalFolderRename" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  role="dialog" aria-labelledby="folderRenameLabel" aria-hidden="true">    
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header pt-0 pb-0">
                    <h4 class="mt-2"><i class="fad fa-pencil-alt text-primary"></i> Rename</h4>                            
                    <button class="btn btn-lg btn-default float-end" :disabled="loading" type="button" data-bs-dismiss="modal" v-on:click="cancel()"><i class="fas fa-times" aria-hidden="true"></i></button>
                </div>
                <div class="modal-body">
                    <!-- Error msg-->
                    <div v-if="folderRenameWarning.visible" class="alert alert-danger">
                        <i class="fas fa-lg fa-times-circle" aria-hidden="true"></i>
                        {{folderRenameWarning.message}}		
                        <button class="btn btn-sm btn-default pull-right" v-on:click="folderRenameWarning.visible = false">Close</button>
                    </div>
                    <!-- /Error msg -->
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="folderName"  placeholder="Folder name">
                        </div>

                    </div>
                </div>                
                <div class="modal-footer pt-1 pb-1">	
                    <button type="button" class="btn btn-secondary"  data-bs-dismiss="modal" v-on:click="cancel()">Cancel</button>
                    <button :disabled="folderName.trim() == '' || loading" class="btn btn-primary" type="button" v-on:click="renameFolder()">
                        Ok
                    </button>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    props: ['data'],
    created () {
        
    },
    data: function() {
        return {
            folderName: '',
            loading: false,
            folderRenameWarning: {'visible': false, 'message': ''}     
        }
    },
    methods: {
        cancel: function() {
            this.folderName = JSON.parse(JSON.stringify(this.data.name));
            this.$parent.modalFolderRename.hide();          
        },
        renameFolder: function() {
            this.$root.processing = true;
            var _this = this;
            var postData = {
				id: this.data.id,
                action: 'rename',
                name: this.folderName.trim()			
			};
			this.axios({
				method: 'post'
				,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/folder.update.php'
				,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
				,data: $.param(postData)             
			}).then(function(response) {   
                _this.$root.processing = false;                      							            
				if (response.data.error) {	
                     _this.$root.setAlert(true, response.data.error, 'alert-danger');									
					return;
				} else {                                        
					_this.$root.currentItem.name = response.data.name;
                    _this.$parent.modalFolderRename.hide();        
                    _this.$root.setAlert(true, 'Folder renamed successfully', 'alert-success');					
				}
			});

        }
       
    },
    watch: {        
        'data': function(newVal) {            
            if (newVal.id && newVal.id != this.$config.rootId) {        
                this.folderName = JSON.parse(JSON.stringify(newVal.name));
            }
        }
    }
}
</script>