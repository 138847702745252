<template>
    <div class="border-bottom pb-2 pt-1">        
        <span v-if="!collapsed">
            <span :key="k" v-for="(b, k) in $root.breadcrumb">
                <span v-if="k == $root.breadcrumb.length - 1">
                    {{b.name}}
                </span>
                <span v-else>
                    <router-link v-if="b.id == $config.rootId" tag="a" :to="{name: 'adminDocuments'}">{{b.name}} </router-link> 
                    <router-link v-else tag="a" :to="{name: 'adminDocuments', params: {id: b.id}}">{{b.name}}</router-link> <span v-if="k != $root.breadcrumb.length - 1"> <i class="fas fa-angle-right"></i> </span>
                </span>
            </span>
        </span>
        <span v-else>
            <!-- First item -->
            <span><router-link tag="a" :to="{name: 'adminDocuments'}">{{firstItem.name}} </router-link><i class="fas fa-angle-right"></i> </span>
            <!-- Collapsed items -->
            <span class="dropdown"><span class="fa-stack ms-1 me-1" id="ctoggle" data-bs-toggle="dropdown"><i class="citems fas fa-ellipsis-h"></i></span><i class="fas fa-angle-right"></i> 
            <div v-on:click="$event.stopPropagation();" id="cdata" class="dropdown-menu dropdown-menu-start" aria-labelledby="ctoggle">
                <div :key="k" v-for="(b, k) in collapsedItems">
                    <span>
                        <i class="fas fa-folder text-secondary"></i><router-link tag="a" :to="{name: 'adminDocuments', params: {id: b.id}}"> {{b.name}}</router-link>
                    </span>
                </div>
            </div></span>
            <!-- Last items -->
            <span><router-link tag="a" :to="{name: 'adminDocuments', params: {id: lastItems[0].id}}">{{lastItems[0].name}}</router-link> <i class="fas fa-angle-right"></i> </span>
            <span> {{lastItems[1].name}}</span>            
        </span>
    </div>    
</template>
<script>
export default {
    data() {
        return {
            firstItem: '',
            lastItems: [],
            collapsedItems: [],
            collapsed: false
        }
    },
    methods: {

    },
    created() {    
        if (this.$root.breadcrumb.length >= 5) {
            this.collapsed = true;
            this.firstItem = this.$root.breadcrumb[0];
            this.lastItems = this.$root.breadcrumb.slice(-2);
            this.collapsedItems = this.$root.breadcrumb.slice(1, -2);

        } else {
            this.collapsed = false;
            this.firstItem = '';
            this.lastItems = [];
            this.collapsedItems = [];
        }    
    }
}
</script>
<style scoped>
div {
    font-size: 1.2rem;
}
a {
    text-decoration: none;
    margin-left: 5px;
}

.citems {
    margin-left: 7px;
    vertical-align: 4px;
}

#cdata {
    white-space: nowrap;
    padding: 5px;
}

#cdata div {
    font-size: 1.0rem;
    padding: 3px 8px;
    margin: 0px;    
}

#cdata div:hover {
	background-color: #e8f0fe;
}

#cdata div:hover span {
	color: #197cde;
}

.fa-stack {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #FFF;
    box-sizing: border-box;    
}

.fa-stack:hover {
    background: #e9ecef;    
}

.fa-stack, .fa-stack i {
    cursor: pointer;
}

.fa-angle-right {
    padding: 0px 4px 0px 1px;
    vertical-align: -1px;
}

</style>