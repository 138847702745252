<template>
  <div id="vue">
    <div v-if="$route.name == 'permalink'">
      <router-view></router-view> 
    </div>
    <div v-else>
      <alert :alert="this.$root.alert"></alert>          
      <router-view :user="this.$root.user" name="navigation"></router-view>    
      <router-view></router-view>
      <foot></foot>
    </div>
  </div>  
</template>
<script>
export default {
  data() {
    return {      
    }
  }
}
</script>
