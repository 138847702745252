<template>    
	<div class="container-fluid" style="padding-bottom: 80px; padding-right: 420px">
		<div class="row pb-2 h-100">
			<div class="col-10 offset-md-2">
				<div class="row">
					<div class="col-5 border-bottom">		
						<h3 class="display-5"><i class="text-primary fa-xs fa-fw fad" :class="{'fa-folder-open': !$root.loading && !$root.processing, 'fa-circle-notch fa-spin': $root.loading || $root.processing}"></i> Documents</h3>
					</div>								
				</div>			
				<div v-if="!$root.loading">									
					<!-- Toolbar -->
					<!--<div class="row">
						<div class="col">
							<breadcrumb></breadcrumb>					
						</div>										
					</div>-->
					<!-- Drive items-->
					<div v-if="$root.driveData.length > 0">
						<ol>
							<li>
								<node :parentid="'root'" :node="$root.driveData[0]"></node>
							</li>
						</ol>                        										
					</div>
					<div v-else>
						<span class="h5 pt-1"><i class="fas fa-circle-notch fa-spin"></i> Building document tree...</span>
					</div>									
				</div>		
			</div>			
		</div>	
		<detail v-if="!$root.loading"></detail>			
	</div>  
</template>

<script>
import node from './node.vue';
import breadcrumb from './breadcrumb.vue';
import detail from './detail.vue';

export default {
	components: {			
		node,
		breadcrumb,
		detail
	},

	data: function() {
    	return {				
			files: [],
			iframeSrc: null
		}
	},
	methods: {	
    	setAlert: function(display, message, css, icon) {
			this.$root.setAlert(display, message, css, icon);			
		},
		clearAlert: function() {
			this.$root.clearAlert();
		},
		download: function(node) {	
			node.downloading = true;	
			var _this = this;	
			var xhr = new XMLHttpRequest();
			xhr.responseType = 'blob';
			xhr.onload = function(event) {				
				var blob = xhr.response;				
				var a = document.createElement('a');
   				a.href = window.URL.createObjectURL(blob);
				a.download = node.name;
				a.dispatchEvent(new MouseEvent('click'));
				node.downloading = false;				
			};							
			xhr.open('GET', _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/file.get.php?action=download&id='+node.id);
			xhr.send();
		}
	},
	computed: {		
	}	
}
</script>
<style>
ol {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
li {        
    padding: 0px 8px;    
    cursor: pointer;
}

.span.link span, .span.file span {
	color: #007bff;
}

span.link span:hover, span.file span:hover {	
	text-decoration: underline;
}

.form-label-group .input-group-text input {
	height: inherit;
	padding-left: 5px !important;
	padding-top: 13px !important;
}

.custom-control-label {
	padding-left: .25rem;
	padding-top: 0.1rem;
}
.custom-control-label::before, 
.custom-control-label::after {  
  width: 1.25rem;
	height: 1.25rem;	
}

@keyframes editing {
 0%  {margin-left: 0px;}
 100% {margin-left: 20px;}
}

@keyframes subdued {
 0%  {margin-left: 20px;}
 100% {margin-left: 0px;}
}

.display-5 {
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}
</style>