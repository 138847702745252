<template>    
    <div class="pt-2" v-if="$parent.loading">
        <h2><i class="text-primary fas fa-circle-notch fa-spin"></i> Loading...</h2>	
    </div>
    <div v-else>   
        <div class="row border-bottom pt-3 mb-2">            
            <div class="col-9">
                <h3 class="me-2">
                    <i class="text-primary fas fa-pen-square"></i> 
                    <span v-if="!newStaff"> Editing account "{{$parent.staff.description}}"</span>
                    <span v-else> Creating a new account</span>                    
                </h3>
            </div>
            <div class="col-3">
                <button type="button" v-on:click="resetUser();" class="mt-1 btn btn-sm btn-primary float-end"><i class="fas fa-arrow-left"></i> Back to staff</button>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="float-start">
                    <i class="fas fa-fw fa-clipboard-user" aria-hidden="true"></i> Staff member information
                </h4>                               
            </div>
            <div class="card-body">
                <div class="row border-bottom pb-2 mb-2">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Login ID</strong></label>
                            </div>			
                            <div class="col-8">
                                <input type="text" v-model="staffState.name" class="form-control" placeholder="User name">                         
                            </div>                         
                        </div>
                        <div class="row" v-if="errors.name">
                            <div class="col">
                                <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Password</strong></label>
                            </div>			                 			
                            <div class="col-8">
                                <input type="text" v-model="password.pass1" class="form-control mb-1" placeholder="New password">   
                                <input type="text" v-model="password.pass2" class="form-control" placeholder="Verify password">                                                                    
                            </div>
                        </div>
                        <div class="row mt-2" v-if="errors.password">
                            <div class="col">
                                <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.password}}</div>
                            </div>
                        </div>
                    </div>                
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Name</strong></label>
                            </div>			
                            <div class="col-8">
                                <input type="text" v-model="staffState.description" class="form-control" placeholder="Display name">     
                            </div>                         
                        </div>
                        <div class="row" v-if="errors.description">
                            <div class="col">
                                <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.description}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Email</strong></label>
                            </div>			
                            <div class="col-8">
                                <input type="text" v-model="staffState.properties.email" class="form-control" placeholder="Email address">     
                            </div>                         
                        </div>
                        <div class="row" v-if="errors.email">
                            <div class="col">
                                <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.email}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Telephone</strong></label>
                            </div>			
                            <div class="col-8">
                                <input type="text" v-model="staffState.properties.telephone" class="form-control" placeholder="Telephone number">     
                            </div>                         
                        </div>
                        <div class="row" v-if="errors.telephone">
                            <div class="col">
                                <div class="alert alert-danger mt-1 p-1"><i class="fas fa-lg fa-exclamation-circle"></i> {{errors.telephone}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Role</strong></label>
                            </div>			
                            <div class="col-8">                                
                                <div class="form-check custom-radio">
                                    <input type="radio" v-model="staffState.properties.role" value="sales" id="roleSales" name="role" class="form-check-input">
                                    <label class="form-check-label" for="roleSales">Sales</label>
                                </div>
                                <div class="form-check custom-radio">
                                    <input type="radio" v-model="staffState.properties.role" value="iam" id="roleIam" name="role" class="form-check-input">
                                    <label class="form-check-label" for="roleIam">Internal Account Manager</label>
                                </div>
                                <div class="form-check custom-radio">
                                    <input type="radio" v-model="staffState.properties.role" value="other" id="roleOther" name="role" class="form-check-input">
                                    <label class="form-check-label" for="roleOther">Other</label>
                                </div>
                            </div>                         
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Portrait</strong></label>
                            </div>			
                            <div class="col-8">
                                <div class="pt-2 pb-2">
                                    <img v-if="staffState.external.logo" class="img-fluid" id="portrait-file-img" :src="staffState.external.logo">
                                    <span v-else>No portrait set</span>
                                </div>                                               
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input class="form-control" type="file" accept=".bmp,.gif,.jpg,.jpeg,.png" ref="portraitUpload" id="portraitFile" v-on:change="setFile()">                                        
                            </div>		                    
                        </div> 
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Active?</strong></label>
                            </div>			
                            <div class="col-8 pt-2">
                                <div class="form-check custom-radio form-check-inline">
                                    <input type="radio" v-model="staffState.properties.active" value="yes" id="userActiveYes" name="userActive" class="form-check-input">
                                    <label class="form-check-label" for="userActiveYes">Yes</label>
                                </div>
                                <div class="form-check custom-radio form-check-inline">
                                    <input type="radio" v-model="staffState.properties.active" value="no" id="userActiveNo" name="userActive" class="form-check-input">
                                    <label class="form-check-label" for="userActiveNo">No</label>
                                </div>                                        
                            </div>                         
                        </div> 
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Advisorlink admin?</strong></label>
                            </div>			
                            <div class="col-8 pt-2">
                                <div class="form-check custom-radio form-check-inline">
                                    <input type="radio" v-model="staffState.properties.admin" value="yes" id="userAdminYes" name="userAdmin" class="form-check-input">
                                    <label class="form-check-label" for="userAdminYes">Yes</label>
                                </div>
                                <div class="form-check custom-radio form-check-inline">
                                    <input type="radio" v-model="staffState.properties.admin" value="no" id="userAdminNo" name="userAdmin" class="form-check-input">
                                    <label class="form-check-label" for="userAdminNo">No</label>
                                </div>                                        
                            </div>                         
                        </div> 
                        <div class="row">
                            <div class="col-4 col-form-label">
                                <label><strong>Color</strong></label>
                            </div>			
                            <div class="col-8 pt-2">
                                <div class="input-group">
                                    <input type="text" v-model="staffState.properties.color" class="form-control" placeholder="######">                                    
                                    <span class="input-group-text color" :style="{'background-color': '#'+staffState.properties.color || '#000000'}"></span>                                        
                                    </div>
                                </div>                                     
                            </div>                         
                        </div> 
                    </div>
                    <div class="col-12 border-top mt-2 mb-0">
                        <button type="button" 
                            v-on:click="update()" 
                            :disabled="saving" 
                            class="btn btn-success btn-sm float-end mt-2"
                        >
                            <span v-if="saving"><i class="fas fa-circle-notch fa-spin"></i> Saving...</span>
                            <span v-else><i class="fas fa-check"></i> Save</span>
                        </button>
                    </div>
                </div>                
            </div>
        </div>       
    </div>
</template>
<script>
const uploadDefault = {name: ''};

import { Collapse } from 'bootstrap';

export default {
	components: {
	},
	data: function() {
		return {  
            staffState: this.$parent.staffState,                        
            toUpload: uploadDefault, //Hold the logo file
            uploading: false,
            password: {hidden: true, pass1: '', pass2: ''},
            errors: {name: false, description: false, password: false, email: false, telephone: false},
            saving: false
		}
	},
	methods: {	        
        setFile: function() {            
            var _this = this;
            if (this.$refs.portraitUpload.files.length > 0) {
                this.toUpload = this.$refs.portraitUpload.files[0]; 
                var fr = new FileReader();
                fr.onloadend = function(event) {                    
                    _this.staffState.external.logo = event.target.result;
                };
                fr.readAsDataURL(this.toUpload);
                $('.portrait-file-label').html(this.toUpload.name);
            } else {
                this.toUpload = uploadDefault;
                $('.portrait-file-label').html('Choose a portrait file');
            }
        },        
        testValid: function(item) {            
            var i = '';
            var update = false;
            var valid = true;
            var error = '';
            var out = {item: '', data: '', valid: true, update: false, error: ''};
            switch (item) {
                case 'name':
                    i = this.staffState.name.trim();
                    update = (i > '' && i != this.$parent.staff.name);
                    var _this = this;
                    //Look in both user and staff data for a username matching the provided one
                    var exists = this.$root.staffData.some(u => {
                        return u.name.toLowerCase() == i.toLowerCase() && _this.$parent.staff.name.toLowerCase() != i.toLowerCase();
                    }) || this.$root.userData.some(u => {
                        return u.name.toLowerCase() == i.toLowerCase() && _this.$parent.staff.name.toLowerCase() != i.toLowerCase();
                    });
                    if (exists) {
                        valid = false;
                        error = 'That login ID is being used by another account';                    
                    }                    
                    if (i == '') {
                        valid = false;
                        error = 'Login ID is required';
                    }
                break;
                case 'description':
                    i = this.staffState.description.trim();
                    update = (i > '' && i != this.$parent.staff.description);
                    if (i == '') {
                        valid = false;
                        error = 'Display name is required';
                    }
                break;
                case 'password':
                    i = this.password.pass2.trim();
                    update = (i > '' && this.password.pass1.trim() == i);                   
                    if (this.staffState.properties.password == '' && (i == '' && this.password.pass1.trim() == '')) {
                        valid = false;
                        error = 'Please create a password';
                    } else if (this.password.pass1.trim() != i) {
                        valid = false;
                        error = 'Both passwords must match';
                    }
                break;
                case 'email': 
                    i = this.staffState.properties.email;
                    update = (i != this.$parent.staff.properties.email);
                break;
                case 'telephone': 
                    i = this.staffState.properties.telephone;
                    update = (i != this.$parent.staff.properties.telephone);
                break;
                case 'properties.role': 
                    i = this.staffState.properties.role;
                    update = (i != this.$parent.staff.properties.role);
                break;               
                case 'external.logo': 
                    i = this.staffState.external.logo;
                    update = (i != this.$parent.staff.external.logo);
                break;
                case 'properties.active': 
                    i = this.staffState.properties.active;
                    update = (i != this.$parent.staff.properties.active);
                break;
                case 'properties.admin': 
                    i = this.staffState.properties.role;
                    update = (i != this.$parent.staff.properties.admin);
                break;
                case 'properties.color': 
                    i = this.staffState.properties.color;
                    update = (i != this.$parent.staff.properties.color);
                break;                
            }
            out.item = item;
            out.data = i;
            out.valid = valid;
            out.update = update;
            out.error = error;
            return out;
        },     
        update: function() {            
            var error = false;
            var fields = [];
            var toUpdate = [];
            var _this = this;
            this.saving = true;
            fields.push(
                this.testValid('name'),
                this.testValid('description'),
                this.testValid('password'),
                this.testValid('email'),
                this.testValid('telephone'),
                this.testValid('properties.role'),
                this.testValid('external.logo'),
                this.testValid('properties.active'),
                this.testValid('properties.admin'),
                this.testValid('properties.color')                
            );
                        
            for (var i = 0; i < fields.length; i++) {                
                this.errors[fields[i].item] = false;                               
                if (!fields[i].valid) {
                    this.errors[fields[i].item] = fields[i].error;
                    if (fields[i].item == 'name') { //Reset username
                        this.staffState.name = this.$parent.staff.name;
                    }
                    error = true;   
                    this.saving = false;                 
                } else {                         
                    toUpdate.push(fields[i]);
                }               
            }    
        
            if (!error) {
                var action;   
                var postData = {
                    type: 'staff',
                    action: '',
                    data: {
                        name: this.staffState.name,
                        description: this.staffState.description,
                        properties: this.staffState.properties,
                        external: this.staffState.external                          
                    }		
                };   
                 
                if (this.newStaff) { //This is the first user save, create a new user         
                    postData.action = 'add';
                    postData.data.properties.password = this.password.pass2;
                } else {
                    postData.action = 'update';
                    postData.data.id = this.$parent.staff.id;               
                    if (this.password.pass2 > '') {
                        postData.data.properties.password = this.password.pass2;    
                    }
                }
                    
                this.axios({
                    method: 'post'
                    ,url: _this.$config[process.env.VUE_APP_ENV].app.base+'data/php/user.update.php'
                    ,headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                    ,data: $.param(postData)             
                }).then(function(response) {                       
                    if (response.data.error) {	
                        _this.$root.setAlert(true, response.data.error, 'alert-danger');	
                        _this.saving = false; //Cancel saving, re-enable buttons								
                        return;
                    } else {
                        _this.staffState.id = response.data.attributes.id;
                        _this.staffState.name = response.data.attributes.name;
                        _this.staffState.description = response.data.attributes.description;                                    
                        _this.staffState.properties = response.data.attributes.properties;                                    
                        _this.staffState.external = response.data.external;
                        _this.$parent.staff = JSON.parse(JSON.stringify(_this.staffState));
                        _this.$root.setAlert(true, 'Staff member updated successfully', 'alert-success');	
                        _this.saving = false; //Finish saving, re-enable buttons
                        if (postData.action == 'add') {
                            _this.$root.staffData.push(response.data.attributes);                            
                        } else {
                            _this.$root.staffData.filter(s => { return s.id == response.data.attributes.id; })[0] = response.data.attributes;
                        }
                        _this.password = {hidden: true, pass1: '', pass2: ''}; //Clear password field
                    }
                });                 
            }
        },        
        resetUser: function() {
            this.$parent.editing = false;
        },
        getStaff: function(id) {
            return this.$parent.staff.find(s => {
                return s.id == id;
            });
        },
        addStaff: function(type) {
            switch (type) {
                case 'iam':
                    this.staffState.external.iam.push({staffId: this.iamSelect.id});
                    this.iamSelect = '';
                break;
                case 'sales':
                    this.staffState.external.sales.push({staffId: this.salesSelect.id, states: []});
                    this.salesSelect = '';
                break;
            }
        },
        stateEdit: function(staff) {
            this.activeStaff = staff;
            $("#stateSelect").modal({
                keyboard: true,
                backdrop: 'static'
            }, 'show');

        },
        removeStaff: function(arr, key) {
            return arr.splice(key, 1);
        },
        testStaff: function(arr, item) {
            return arr.some(i => {
                return item.id == i.staffId;
            });
        } 
    },
    computed: {
        testUpdateS1: function() {            
           return this.testValid('name').update || this.testValid('description').update || this.testValid('properties.password').update || this.testValid('properties.active').update;
        },
        testUpdateS2: function() {            
           return this.testValid('external.bluerock').update || this.testValid('external.logo').update;
        },
        testUpdateS3: function() {            
           return this.testValid('external.iam').update || this.testValid('external.sales').update;
        },
        testUpdateS4: function() {            
           return this.testValid('external.message').update;
        },
        testUpdateS5: function() {            
           return this.testValid('external.access').update;
        },
        newStaff: function() {
            return this.staffState.id == '';
        }
    }
}
</script>
<style scoped>
.color { min-width: 80px; }
.custom-file-control.selected::after {
    content: "" !important;
}
.form-group {
    margin-bottom: 0;
}
#tree-root {
    margin-top: 0px;
}

.accordion-body {
    padding: 10px;
}

h4 {
    cursor: pointer;
}
.alert {
    margin-bottom: 0;
}

.accordion-header h4 span:hover {
    text-decoration: underline;
}

#tree-root .node {
    padding: 0px 0px 0px 10px;
}

.node > div:hover {
    background-color: #cce5ff;
}

.node > div {
    padding: 5px 0px 5px 5px;
}

ol {
    list-style-type: none;
    margin: 0px !important;
    padding: 0px !important;
}

li {
    margin-right: 0px !important;
    padding-right: 0px !important;
}

</style>