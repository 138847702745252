import { reactive } from 'vue'

export const staffState = reactive({
  id: "",  
  name: "",
  description: "",
  properties: {
    type: "staff",
    password: "",
    active: "no",
    email: "",
    telephone: "",
    role: "sales",
    admin: "no",
    color: ""
  },
  external: {
    logo: ""
  }
})