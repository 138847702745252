<template>   
    <div class="node" v-on:click.stop="setCurrent(node)">	             				
		<div class="border-bottom">
			<span 
				class="name folder" 
				v-if="node.mimeType == 'application/vnd.google-apps.folder'">
				<i class="fa-lg" :class="{'fad fa-folder-open': !collapsed, 'fas fa-folder': collapsed}"></i> {{node.name}}
			</span>
            <span v-else-if="node.mimeType == 'text/x-uri'" class="link" v-on:dblclick.stop="download(node)">
                <i class="fas fa-external-link-square" /> <span>{{node.name}}</span>
            </span>
            <span v-else class="file"  v-on:dblclick.stop="download(node)">
                <img v-if="!node.downloading" :src="node.iconLink" />
				<i v-else class="fas fa-circle-notch fa-spin"></i> <span>{{node.name}}</span>
            </span>                 
            <!--            
			<span v-show="hasAccess == 'all'"><i class="text-success fas fa-star"></i></span>
			<span v-show="hasAccess == 'some' || (hasAccess == 'none' && some != 'none')"><i class="text-success fas fa-star-half-alt"></i></span>			
			-->
		</div>	
		<ol v-show="!collapsed">
			<!-- The nodes, activeNode is set on click -->            
			<li v-show="node.children.length > 0" v-for="(n, i) in node.children">
				<node :parentid="node.id" :node="n"></node>		
			</li>			
		</ol>			
	</div>                                      
</template>

<script>
import node from './node.vue';
export default {
    name: "node",
    props: {
        node: Object,
        parentid: String
    },
    data() {
        return {   
            count: 0,
            collapsed: true,                      
            some: 'none',
            tempNode: Object.assign({}, this.node)			             
        }
    },      
    methods: {    
		download: function(node) {
			this.$parent.download(node);			
		},
		setCurrent: function(node) {
			if (node.mimeType == 'application/vnd.google-apps.folder') {
				this.collapsed = !this.collapsed;
			}
			this.$root.currentItem = node;
		},
		bubbleStatus: function(status) {					
			this.some = status;            
			if (this.parentid != 'root') {            
				for (var i = 0; i < this.$parent.node.children.length; i++) {						
					if (this.$parent.node.children[i].some != 'none' && status == 'none') {
						return;
					}
				}
				this.$parent.bubbleStatus(status);
			}
		}       
    },
    computed: {
        hasAccess: function() {			
			var out = '';
			if (this.node.mimeType == 'application/vnd.google-apps.folder' && this.node.children.length) { //Folders with children, we'll check the children to see if they're the same, or different and deal with the parent accordingly
				var children = this.node.children;
				var status = userState.external.access.indexOf(this.node.id);
				if (children.length) {
					//What's the inclusion status of the first child?
					var c1 = userState.external.access.includes(this.node.children[0].id);
					var diff = false;  //Assume everything is the same for now
					for (var i = 0; i < this.node.children.length; i++) {
						//If the inclusion status of any of the other children is different from the first child's inclusion status, break
						if (userState.external.access.includes(this.node.children[i].id) != c1) {
							diff = true;
							break;
						}
					}
					if (diff) { //The inclusion status of the children are different, remove access for the parent
						if (status != -1) {
							userState.external.access.splice(status, 1);
						}											
						out = 'some';
					} else { //The inclusion status of the children are the same
						if (c1) {
							if (status == -1) { //If the parent element doesn't have access, set it as all children have access
                            userState.external.access.push(this.node.id);
							}
							out = 'all';
						} else { //If the parent node has access, remove it as no children have access
							if (status != -1) {
								userState.external.access.splice(status, 1);
							}														
							out = 'none';
						}
					}
				}				
			} else { //Not a folder, either assigned or not
				if (userState.external.access.includes(this.node.id)) {
					out = 'all';
				} else {
					out = 'none';
				}
			}
			this.bubbleStatus(out);
			return out;
		}
    }
}
</script>