<template>
    <nav class="navbar navbar-expand-lg bg-custom">
        <span class="navbar-brand">
            <a href="/">      
            <div>{{$root.$config.title}}</div>     
            </a>
        </span>
        <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>-->
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <!--<ul class="navbar-nav">
				<li><router-link tag="a" class="nav-item nav-link" :to="{name: 'documents', params: {id: $root.config.rootId}}"><i class="fad fa-folder-open"></i> Documents</router-link></li>
				<li><router-link tag="a" class="nav-item nav-link" :to="{name: 'trash', params: {}}"><i class="fad fa-trash-alt"></i> Trash</router-link></li> 				
			</ul>-->
            <search></search>       
        </div>
        <span v-if="$root.user.id" class="navbar-text">		            	            
            <span>
                <i class="fas fa-user"></i> You are logged in as {{$root.user.description}}
                <button type="button" v-on:click="$root.logout()" class="ms-2 btn btn-sm btn-primary"><i class="fad fa-sign-out"></i> Logout</button>
            </span>
        </span>
    </nav>
</template>

<script>       
    import search from '../user/search/main.vue';
    export default {        
        components: {
            search: search
        },
        methods: {            
        }
    }
</script>
<style scoped>
    .navbar {
        height: 56px;
    }
    a {
        text-decoration: none;
    }
</style>