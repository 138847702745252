<template>    
	<div class="container-fluid"  style="padding-bottom: 80px; padding-right: 420px">
		<div class="row pb-2 h-100">
			<div class="col-10 offset-md-2">
				<div class="row">
					<div class="col-6 border-bottom">		
						<h3 class="display-5"><i class="text-primary fa-xs fa-fw fad" :class="{'fa-file-search': !$root.loading && !$root.processing, 'fa-circle-notch fa-spin': $root.loading || $root.processing}"></i> Search results</h3>
					</div>			
					<div class="col-6 border-bottom text-end">
						<router-link tag="button" class="btn mt-2 btn-sm btn-primary" :to="{name: 'userDocuments', params: {id: $root.config.rootId}}"><i class="fas fa-arrow-left"></i> Back to documents</router-link>
					</div>					
				</div>
			
				<div v-if="!$root.loading">	
					<div class="mt-2">
						<div class="input-group form-label-group search">
							<div class="input-group-text bg-white">
								<i class="fas fa-filter"></i>
							</div>
							<input class="form-control border-start-0 shadow-none" v-model="filter" type="text" placeholder="Filter results by title" aria-label="Filter">  							
							<label>Filter results by title</label>	
							<button type="button" :disabled="filter == ''" v-on:click="filter = ''" class="btn btn-danger"><i class="fas fa-times"></i> Clear</button>                
						</div>
					</div>									
					<!-- Drive items-->
					<div>										
						<item-list :data="paginatedData" :filter="filter"></item-list>										
					</div>	
					<div class="row mt-2">
						<div class="col-2">
							<button class="btn btn-primary" type="button" :disabled="pageNumber == 0" v-on:click="prevPage()"><i class="fas fa-chevron-left"></i> Back</button>
						</div>
						<div class="col-8" style="text-align: center">
							<span v-if="pageCount">
								page {{pageNumber + 1}} of {{pageCount}}
							</span>
						</div>
						<div class="col">
							<button class="float-end btn btn-primary" type="button" :disabled="pageNumber == pageCount - 1 || !pageCount" v-on:click="nextPage()">Next <i class="fas fa-chevron-right"></i></button>
						</div>
					</div>          			
				</div>		
			</div>
		</div>
		<detail v-if="!$root.loading"></detail>
	</div>  
</template>

<script>
import itemList from './item.list.vue';
import detail from './detail.vue';

export default {
	components: {			
		itemList,		
		detail		
	},

	data: function() {
    	return {				
			size: 15,
            pageNumber: 0,
			filter: ''
		}
	},
	methods: {	
		hideContextMenu(id) {
      		const element = document.getElementById(id);
      		if (element) {
        		element.classList.remove("vue-simple-context-menu--active");
        		//this.$emit("menu-closed");
      		}
    	},
    	setAlert: function(display, message, css, icon) {
			this.$root.setAlert(display, message, css, icon);			
		},
		clearAlert: function() {
			this.$root.clearAlert();
		},		
        nextPage: function() {
            if (this.pageNumber < this.pageCount) {
                this.pageNumber++;
            }
        },
        prevPage: function() {
            if (this.pageNumber > 0) {
                this.pageNumber--;
            }
        }        
    },
    computed: {        
        pageCount: function() {
            let l = this.filteredData.length;
            let s = this.size;
            var c = Math.floor(l/s);
            if (l % s > 0) {
                c++;
            }					
            return c;
        },
        paginatedData: function() {            
            if (this.pageNumber > this.pageCount) {                
                this.pageNumber = 0;
            }
            const start = this.pageNumber * this.size;            
            const end = start + this.size;
            return this.filteredData.slice(start, end);
        },
		filteredData: function() {			
            return this.$root.searchData.filter(a => {
				return a.name.toLowerCase().includes(this.filter.toLowerCase());                
            });        
		}
    }
	
}
</script>
<style scoped>
.form-label-group > label {
	margin-left: 2.5rem !important;
}
</style>